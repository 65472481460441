import { useNavigate } from "react-router-dom"
import "./WhyChooseUs.css"
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";

const cardData = [
  {
    icon:"/images/icons/card-icon-1.webp",
    title:"Network Design",
    content:"Comprehensive services  featuring design processes, dedicated program managers, and detailed deployment plans for hardware, IT infrastructure, signage software, training, and operational planning.",
    link : "/network-design"
  },
  {
    icon:"/images/icons/card-icon-2.webp",
    title:"Digital Signage Solutions",
    content:"Thousands of installations, all types of business channels. We understand the specific requirements of each channel, and how to help get your project completed and maintained.",
    link : "/digital-signage-solution"
  },
  {
    icon:"/images/icons/card-icon-3.webp",
    title:"Everything Audio",
    content:"Enhance communication, inspire your team, and captivate clients with our cutting-edge digital audio solutions. Perfect for any high-traffic area, from waiting rooms to break rooms.",
    link : "/everything-audio"
  },

  {
    icon:"/images/icons/card-icon-4.webp",
    title:"Preventative Maintenance",
    content:"There is a better way to manage your network environment. We offer end-to-end managed services for seamless business operations.",
    link : "/preventative-maintenance"
  },
  {
    icon:"/images/icons/card-icon-5.webp",
    title:"Structured Wiring",
    content:"Structured wiring is the highway that connects all things visual and audio. It needs to be planned thoughtfully, properly installed and you need a partner on call if anything needs changed, upgraded or serviced. ",
    link : "/structured-wiring-perfected"
  },
  {
    icon:"/images/icons/card-icon-6.webp",
    title:"LPWP",
    content:"From kits to custom orders stored in our warehouse and shipped across the country - We source mounting, cabling, audio, panels, monitor, mounts and everything else needed for custom projects. ",
    link : "/logistics-procurement"
  },
  {
    icon:"/images/icons/card-icon-7.webp",
    title:"Surveys and Data Insights",
    content:"Comprehensive site surveys to assess the condition and functionality of a variety of equipment. Data from site surveys are turned into  a suite of detailed reports to give clients a clear understanding of their technical infrastructure and any actions needed.",
    link : "/environment-surveys"
  },
  {
    icon:"/images/icons/card-icon-8.webp",
    title:"Remote Monitoring",
    content:"Our Remote Monitoring and Support service is like having a universal remote for all your connectivity needs. We bring together all types of networks—whether it's cable or cellular—under one roof. One call, one point of contact and one bill. It's that simple.",
    link : "/remote-monitoring-support"
  },
]

function WhyChooseUs() {
  const navigate = useNavigate()
  return (
    <div className="whychooseus-container py-4">
      <div className="container py-2">
        <div className="row">
          <div className="col-sm-12 text-center my-5 text-content">
            <p className="sub-heading">our Services</p>
            <h3 className="body-heading"><span className="color-blue body-heading">Comprehensive Technology</span> Services</h3>
            <p className="body-paragraph mt-3">Integrated, end-to-end managed services designed to streamline business operations and cost across various sectors.</p>
          </div>
          <div className=" ">
          <div className="col-12">

            
                  <Swiper
                  spaceBetween={50}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: true,
                    pauseOnMouseEnter: true,
                  }}
                  loop={true}
                  modules={[Autoplay]}
                  breakpoints={{
                      200:{
                        slidesPerView: 1,
                      },
                      1000: {
                        slidesPerView: 3,
                      },
                    }}
                  className=" py-3 mx-auto"
                  >
                    {
                      cardData.map((ele)=>{
                        return(
                          <SwiperSlide>
                          <div className=" ">
                           <div className="whychooseus-card-box mx-auto">
                             <img src={ele.icon}/>
                             <h5 className="card-title font-bebas">{ele.title}</h5>
                             <p className="body-paragraph">{ele.content}</p>
                             <p onClick={()=>{navigate(ele.link)}} className="card-link font-bebas mt-3">Read More</p>
                           </div>
                         </div>
                         </SwiperSlide>
                        )
                        }
                        )
                    }
              </Swiper>
                {/* <div className="col-lg-4 "> */}
                  {/* <div className="whychooseus-card-box mx-auto"> */}
                    {/* <img src={ele.icon}/> */}
                    {/* <h5 className="card-title font-bebas">{ele.title}</h5> */}
                    {/* <p className="body-paragraph">{ele.content}</p> */}
                    {/* <p onClick={()=>{navigate(ele.link)}} className="card-link font-bebas mt-3">Read More</p> */}
                  {/* </div> */}
                {/* </div> */}

            
          </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhyChooseUs