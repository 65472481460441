import React from 'react'

function IndustryCard(props) {
  return (
    <div className='col'>
        <div className='industry-card mx-auto' style={{width:"200px"}}>
            <div className='card-icon-container'>
                <img src={props.img} className='card-icon'/>
            </div>
            <p className='body-paragraph mt-2'>{props.title}</p>
        </div>  
    </div>
  )
}

export default IndustryCard