import React, { Fragment } from "react";
import HeroBanner from "../../components/Common Banner Component/HeroBanner";
import Testimonials from '../../components/Testimonials/Testimonials'; 
import TextContent from "../../components/TextContent/TextContent";
import TextParalalaxEffect from "../../components/TextParalalaxEffect/TextParalalaxEffect";
import AboutParalalaxEffect from "../../components/AboutParalalaxEffect/AboutParalalaxEffect";

function EverythingAudio() {
    return (
        <div style={{background:"#022A44"}}>  
        <HeroBanner
          Heading={`Everything`}
          HighlightedSpan={" Audio "}
          Paragraph={`Enrich your environment, improve communications, motivate employees, engage customers.`}
          bgImg={'/images/creatives/ea-hero-bg.jpg'}
          isChevron={true}
          isBtn={false}
        />
        
        <TextContent
            heading = "Elevate Your "
            spanHeading="Space"
            shiftRow={true}
            content={"Enhance communication, inspire your team, and captivate clients with our cutting-edge digital audio solutions. Perfect for any high-traffic area, from waiting rooms to break rooms."}
            img={"/images/creatives/ea-text-1.png"}
            mobileTextCenter={true}
            listPointList={false}
            btn={true}
            btnText={"Let's Connect"}
            btnRef="tel"
        />
        <AboutParalalaxEffect/>
        <TextContent
            heading = "Stay "
            spanHeading="Connected"
            shiftRow={false}
            content={"The Audio-visual industry continues to evolve in technology to allow us to more easily communicate and interact. Many companies from small startups to international Fortune 500 companies continue to enhance their use of Digital AV technology. Blue Fysh is positioned to deliver infrastructure to allow your business to stay connected on every level. We take away the worry and concerns of setup and management of these systems and we help maintain your system current with today's standards."}
            img={"/images/creatives/ea-text-2.webp"}
            mobileTextCenter={true}
            listPointList={false}
            btn={false}
        />
    
        <TextParalalaxEffect
            heading="Let Us Transform "
            spanHeading="Your Environment"
            content="Blue Fysh has the ability to design and procure all you audio video needs. from simple audio, video requirements to full digital signage infrastructure for your organization. With years of experience in planning and implementing your A/V commercial, retail, boardroom, conference rooms, manufacturing, corporate or residential high rise, requirements either indoor our outdoor we are here to carry out your plans to communicate your messages."
            btn={false}
            btnText="OUR CASE STUDIES"
            btnHref="#"
        />
    
         
      </div>
      )
}

export default EverythingAudio