import React from 'react'
import HeroBanner from "../components/Common Banner Component/HeroBanner";
import ImgByContent from '../components/Image By Content/ImgByContent';
import WorkIndustry from '../components/WorkIndustry/WorkIndustry';
import TextContent from '../components/TextContent/TextContent';
import Testimonials from '../components/Testimonials/Testimonials';

// 

function OurClient() {
  return (
    <div>
        <HeroBanner
            Paragraph={``}
            Heading={`OUR `}
            HighlightedSpan={"CLIENTS"}
            whiteHiglightedText="International Clients Across Many Business Sectors"
            isBtn={false}
            bgImg={'/images/creatives/client_banner.webp'}
            isChevron={true}
        />

      <ImgByContent
        Heading={"Digital Connections & "}
        HighlightedHeading={"Creative Displays"}
        subHeading={""}
        Paragraph={`At Blue Fysh, we make digital connections and creative displays work for our clients. We're in the business of partnering with companies across all sectors to wire them into the digital age. Our expertise lies in crafting and implementing digital displays that ensure constant connectivity, adaptability, and visibility.`}
        btnText={""}
        imgLink="/images/creatives/client-text-0.1.webp"
      />

      <WorkIndustry/>

      <div className='container text-center my-5 pb-3'>
        <h3 className='body-heading px-5'>Some Examples of our work with amazing clients that <span className='color-blue'>we are proud to share</span> </h3>

      </div>

      <ImgByContent
        Heading={"Fast Food & "}
        HighlightedHeading={"Hospitality"}
        subHeading={""}
        Paragraph={`Streamlined for Speed and Service. Our tailored digital solutions cater to the fast-moving nature of the fast food and hospitality industries: \nDrive-through innovation: Digital Drive-Thru Menu Boards, integrated, headset-connected digital menu boards for efficient service and an enhanced customer experience.\nIn-store interaction: Dynamic digital menu boards at the point of order, bolstered by sophisticated security systems and remote surveillance, all interconnected and equipped with smart sensors and timers for optimum performance.`}
        btnText={"Let's Connect"}
        href='tel'
        shiftRow={true}
        imgLink="/images/creatives/client-text-1.webp"
      />

      <ImgByContent
        Heading={"Comprehensive Structured Cabling and "}
        HighlightedHeading={"Network Installations"}
        subHeading={""}
        Paragraph={`Blue Fysh specializes in top-tier Structured Cabling & Network Installations. Notably, we've successfully implemented robust networks for Mr. Lube and Tim Hortons locations nationwide. Choose us for dependable and scalable network solutions that propel your business forward with seamless connectivity.`}
        btnText={"Let's Connect"}
        href='tel'
        shiftRow={false}
        imgLink="/images/creatives/client-text-0.2.webp"
      />
      {/* <ImgByContent
        Heading={"Security and "}
        HighlightedHeading={"Remote Monitoring Systems"}
        subHeading={""}
        Paragraph={`need copy`}
        btnText={"Let's Connect"}
        href='tel'
        shiftRow={true}
        imgLink="/images/creatives/home-text-1.webp"
      /> */}
      <ImgByContent
        Heading={"Commercial & "}
        HighlightedHeading={"Specialized Sectors"}
        subHeading={""}
        Paragraph={`Engaging Digital Environments. We empower commercial spaces, automotive dealerships, call centers, and government agencies with digital displays and video monitors designed to engage and inform.\nInformation at a glance: Strategic placement of information boards for optimal visitor and customer communication.\nVisual impact: Striking video walls that captivate and inform.\nBrand presence: LED ribbons that enhance brand visibility and customer engagement.`}
        btnText={"Let's Connect"}
        href='tel'
        shiftRow={true}
        imgLink="/images/creatives/client-text-0.3.webp"
      />
      <ImgByContent
        Heading={"Education, Finance & "}
        HighlightedHeading={"Healthcare"}
        subHeading={""}
        Paragraph={`Visual Solutions That Inform and Guide \n Universities, financial services, and healthcare providers can transform their spaces with our digital solutions, from informative videos to intuitive wayfinding systems.\nNavigational clarity: Wayfinding screens that guide and inform with ease.\nExpert installation: Professional digital screen installations tailored to your environment.\nInfrastructure you can trust: Robust structured cabling for unwavering reliability.`}
        btnText={"Let's Connect"}
        href='tel'
        shiftRow={false}
        imgLink="/images/creatives/client-text-2.webp"
      />
      {/* <ImgByContent
        Heading={"Our Track Record "}
        HighlightedHeading={"Speaks Volumes"}
        subHeading={""}
        Paragraph={`With a vast portfolio of installations across diverse business channels, Blue Fysh stands as a beacon of innovation in digital display solutions. Take a look at comments from our  satisfied clients and see how we can bring your digital aspirations to fruition.`}
        btnText={"Let's Connect"}
        href='tel'
        shiftRow={true}
        imgLink="/images/creatives/home-text-1.webp"
      /> */}
      <div style={{height:"50px"}}></div>

      <Testimonials/>
    </div>
  )
}

export default OurClient