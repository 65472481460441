import React from 'react'
import "./AboutIcons.css"
import AboutIconContainer from './AboutIconContainer'

function AboutIcons() {
  return (
    <div className='AboutIcons-container '>
        <div className=''>

        <div className='row align-items-center'>

            <AboutIconContainer 
                img={'/images/icons/community.svg'}
                rating={"9294+"}
                ratingNumber={9294}
                repeatScroll={true}
                sliderSpeed={10}
                title={"Installations"}
                />
            <AboutIconContainer 
                img={'/images/icons/thumbs.svg'}
                rating={"625+"}
                ratingNumber={625}
                title={"Projects Done"}
                sliderSpeed={50}

                />
            <AboutIconContainer 
                img={'/images/icons/rank.svg'}
                rating={"20+"}
                ratingNumber={20}
                title={"Years Experience"}
                noScroll={true}
                sliderSpeed={50}
                />
            <AboutIconContainer 
                img={'/images/icons/loop.svg'}
                rating={"99%"}
                ratingNumber={99}
                title={"Repeated Customers"}
                noScroll={true}
                sliderSpeed={50}
                />
        </div>
        </div>
    </div>
  )
}

export default AboutIcons