import React, { useState ,useRef,useEffect} from "react";
import "./Header.css";
import { Link, useNavigate } from "react-router-dom";

const Header = () => {
  const [menuState, setMenuState] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpenDesk, setIsDropdownOpenDesk] = useState(false);
  const [isDropdownOpenServices, setIsDropdownOpenServices] = useState(false);
  const [isDropdownOpenServicesDesk, setIsDropdownOpenServicesDesk] = useState(false);
  const [isDropdownOpenContact, setIsDropdownOpenContact] = useState(false);
  const [isDropdownOpenContactDesk, setIsDropdownOpenContactDesk] = useState(false);
  const navigate = useNavigate()
  const dropdownRef = useRef(null);
  const dropdownServicesRef = useRef(null);
  const dropdownRefContact = useRef(null);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && isDropdownOpenDesk) {
        if (!event.target.closest('.app')) {
          setIsDropdownOpenDesk(false);
        }
      }

      if (dropdownServicesRef.current && !dropdownServicesRef.current.contains(event.target) && isDropdownOpenServicesDesk) {
        if (!event.target.closest('.app')) {
          setIsDropdownOpenServicesDesk(false);
        }
      }
      if (dropdownRefContact.current && !dropdownRefContact.current.contains(event.target) && isDropdownOpenContactDesk) {
        if (!event.target.closest('.app')) {
          setIsDropdownOpenContactDesk(false);
        }
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isDropdownOpenDesk , isDropdownOpenServicesDesk , isDropdownOpenContactDesk]);

  const clickCountRef = useRef(0);

const onClickService = (e) => {
    e.preventDefault();
    
      clickCountRef.current++;
      if (clickCountRef.current === 1) {
        setIsDropdownOpen(true);
      } 
      else if (clickCountRef.current === 2) {
        setMenuState(false)
        setIsDropdownOpen(false)
        navigate("/blog");
        clickCountRef.current = 0;
      }
  };
const onClickServices = (e) => {
    e.preventDefault();
    
      clickCountRef.current++;
      if (clickCountRef.current === 1) {
        setIsDropdownOpenServices(true);
      } 
      else if (clickCountRef.current === 2) {
        setMenuState(false)
        setIsDropdownOpenServices(false)
        navigate("/services");
        clickCountRef.current = 0;
      }
  };
const onClickContact = (e) => {
    e.preventDefault();
    
    setIsDropdownOpenContact((prev)=>!prev)
      // clickCountRef.current++;
      // if (clickCountRef.current === 1) {
      //   setIsDropdownOpenContact(true);
      // } 
      // else if (clickCountRef.current === 2) {
      //   setMenuState(false)
      //   setIsDropdownOpenContact(false)
      //   // navigate("/contact");
      //   clickCountRef.current = 0;
      // }
  };

  const menuClick = () => {
    setMenuState((prev) => !prev);
  };
  
  return (
    <div className={`header_technohood`}>
      <div className="header-wrapper container">
        <nav className="navbar d-md-flex flex-md-row d-block">
          <nav className="navbar-container px-0 py-2">
            <div className="nav-elements d-flex justify-content-between align-items-center px-lg-2">
              <img src="/images/icons/logo.png" className="nav-logo" onClick={()=>{setIsDropdownOpen(false);setIsDropdownOpenDesk(false); navigate("/home")}}/>
              <input className="d-none" type="checkbox" id="menu-toggle" />
              <nav className="d-flex align-items-center gap-3">
                <ul
                  className="font-bebus nav-list d-lg-flex gap-4 gap-xl-5 align-items-center d-none main-nav"
                  id="js-menu"
                >
                  <li>
                    <Link to={"/"} className="nav-links font-bebus" onClick={()=>{setIsDropdownOpen(false);setIsDropdownOpenDesk(false);}}>
                      HOME
                    </Link>
                  </li>
                  <li>
                    <Link to={"/about"} className="nav-links font-bebus font-bebus" onClick={()=>{setIsDropdownOpen(false);setIsDropdownOpenDesk(false);}}>
                      About
                    </Link>
                  </li>
                  {/* <li>
                    <Link to={"/services"} className="nav-links font-bebus font-bebus" onClick={()=>{setIsDropdownOpen(false);setIsDropdownOpenDesk(false);}}>
                      Services
                    </Link>
                  </li> */}
                  <li>

                      <div className="navigation-dropdown" ref={dropdownServicesRef}>

                        <li onMouseEnter={()=>{setIsDropdownOpenServicesDesk(true)}} >

                            <div className="dropdown-trigger d-flex align-items-center" >
                                <Link to={"/services"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenServicesDesk(false)}} >Services</Link>
                                <img src={`/images/icons/drop-down-icon.png`} className={isDropdownOpenServicesDesk ? "rotate-icon arrow-icon":"rotate-back arrow-icon" }/>
                            </div>
                            <div className={`dropdown-content ${isDropdownOpenServicesDesk ? 'open' : 'none-delay'}`} onMouseEnter={()=>{setIsDropdownOpenServicesDesk(true)}} onMouseLeave={()=>{setIsDropdownOpenServicesDesk(false)}}>

                      

                            <Link to={"/preventative-maintenance"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenServicesDesk(false)}}>
                              <p className="mb-0" style={{textTransform:"capitalize"}}>Preventative Maintenance</p>
                            </Link>
                            <Link to={"/digital-signage-solution"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenServicesDesk(false)}}>
                              <p className="mb-0" style={{textTransform:"capitalize"}}>Digital Signage Solutions</p>
                            </Link>
                            <Link to={"/everything-audio"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenServicesDesk(false)}}>
                              <p className="mb-0" style={{textTransform:"capitalize"}}>Audio Installations</p>
                            </Link>
                            <Link to={"/network-design"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenServicesDesk(false)}}>
                              <p className="mb-0" style={{textTransform:"capitalize"}}>Network Design</p>
                            </Link>
                            <Link to={"/structured-wiring-perfected"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenServicesDesk(false)}}>
                              <p className="mb-0" style={{textTransform:"capitalize"}}>Structured Wiring</p>
                            </Link>
                            <Link to={"/logistics-procurement"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenServicesDesk(false)}}>
                              <p className="mb-0" style={{textTransform:"capitalize"}}>LPWP</p>
                            </Link>
                            <Link to={"/environment-surveys"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenServicesDesk(false)}}>
                              <p className="mb-0" style={{textTransform:"capitalize"}}>Surveys and Data Insights</p>
                            </Link>
                            <Link to={"/remote-monitoring-support"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenServicesDesk(false)}}>
                              <p className="mb-0" style={{textTransform:"capitalize"}}>Remote Monitoring</p>
                            </Link>
                            
                            </div>
                          </li>
                      </div>
                      </li>
                  <li>
                    <Link to={"/call-center-support-hub-support"} className="nav-links font-bebus font-bebus" onClick={()=>{setIsDropdownOpen(false);setIsDropdownOpenDesk(false);}}>
                    Support Hub
                    </Link>
                  </li>
                
                  <li>
                    <Link to={"/client"} className="nav-links font-bebus" onClick={()=>{setIsDropdownOpen(false);setIsDropdownOpenDesk(false);}}>
                    Clients
                    </Link>
                  </li>
                  <li>

                <div className="navigation-dropdown" ref={dropdownRef}>

                  <li onMouseEnter={()=>{setIsDropdownOpenDesk(true)}} >

                      <div className="dropdown-trigger d-flex align-items-center" >
                          <Link to={"/blog"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenDesk(false)}} >Resources</Link>
                          <img src={`/images/icons/drop-down-icon.png`} className={isDropdownOpenDesk ? "rotate-icon arrow-icon":"rotate-back arrow-icon" }/>
                      </div>
                      <div className={`dropdown-content ${isDropdownOpenDesk ? 'open' : 'none-delay'}`} onMouseEnter={()=>{setIsDropdownOpenDesk(true)}} onMouseLeave={()=>{setIsDropdownOpenDesk(false)}}>
              
                      {/* <Link to={"/case-studies"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                        <p className="mb-0" style={{textTransform:"capitalize"}}>Case Studies</p>
                      </Link> */}
                      <Link to={"/blog"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                        <p className="mb-0" style={{textTransform:"capitalize"}}>Blogs</p>
                      </Link>
                      <Link to={"/careers"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                        <p className="mb-0" style={{textTransform:"capitalize"}}>Careers</p>
                      </Link>
                      </div>
                    </li>
                </div>
                </li>
                  <li>
                  <div className="navigation-dropdown" ref={dropdownRefContact}>

                      <li onMouseEnter={()=>{setIsDropdownOpenContactDesk(true)}} >

                          <div className="dropdown-trigger d-flex align-items-center" >
                              {/* <Link to={"/contact"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenContactDesk(false)}} >Contact</Link> */}
                              <p className="nav-links nav-txt mb-0" onClick={()=>{setIsDropdownOpenContactDesk((prev)=> !prev)}} >Contact</p>
                              <img src={`/images/icons/drop-down-icon.png`} className={isDropdownOpenContactDesk ? "rotate-icon arrow-icon":"rotate-back arrow-icon" }/>
                          </div>
                          <div className={`dropdown-content ${isDropdownOpenContactDesk ? 'open' : 'none-delay'}`} onMouseEnter={()=>{setIsDropdownOpenContactDesk(true)}} onMouseLeave={()=>{setIsDropdownOpenContactDesk(false)}}>

                          <Link to={"/contact-usa"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenContactDesk(false)}}>
                            <p className="mb-0" style={{textTransform:"capitalize"}}>USA</p>
                          </Link>
                          <Link to={"/contact"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenContactDesk(false)}}>
                            <p className="mb-0" style={{textTransform:"capitalize"}}>Canada</p>
                          </Link>
                         
                          </div>
                        </li>
                      </div>
                  </li>
                </ul>
              </nav>

              <label
                value="menu-toggle"
                onClick={menuClick}
                className="responsive-menu-btn d-lg-none d-inline"
              >
                <img
                  src="/images/icons/icons8-hamburger-menu-50.png"
                  className="img-fluid menu-btn"
                  alt="menu"
                />
              </label>

              <div className="nav-buttton ml-5 d-lg-flex d-none gap-2 gap-xl-4 extras-btns">
                <a
                  href="mailto:sales@bluefysh.com,info@bluefysh.com"
                  style={{textDecoration:"none"}}
                >
                <div ><button className="blue-btn">
                Get Started 
                </button></div>
                </a>
                {/* <button className="get-quote-btn" onClick={()=>{setIsDropdownOpen(false);setIsDropdownOpenDesk(false);navigate("/contact")}}>GET A QUOTE</button> */}
              </div>
            </div>
          </nav>

          {/* Dropdown in responsive mode */}
          <nav className="responsive-popup-menu w-100">
            <ul
              className="font-bebus resp-nav-list d-lg-none w-100 gap-4 hs-dropdown [--trigger:hover] pl-4 py-3"
              style={{ display: menuState ? "block" : "none" }}
            >
              <li>
                <Link to={"/"} className="nav-links font-bebus" onClick={()=>{setMenuState(false)}}>
                  Home
                </Link>
              </li>
              <li>
                <Link to={"/about"} className="nav-links font-bebus" onClick={()=>{setMenuState(false)}}>
                  About
                </Link>
              </li>
              
              {/* <li>
                <Link to={"/services"} className="nav-links font-bebus" onClick={()=>{setMenuState(false)}}>
                Services
                </Link>
              </li> */}
              <li>

                <div className="navigation-dropdown">
                <div className="dropdown-trigger d-flex align-items-center" style={{color:"black"}} onClick={onClickServices} >
                      <a className="nav-links nav-txt" >Services</a>
                      <img src='/images/icons/drop-down-icon-b.png' className={isDropdownOpenServices ? "rotate-icon arrow-icon":"rotate-back arrow-icon" } />
                  </div>
                  <div className={`dropdown-content ${isDropdownOpenServices ? 'open' : 'd-none'}`} style={{top:"85%",left:"0%"}}>
                  

                  <Link to={"/preventative-maintenance"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenServices(false); setMenuState(false)}}>
                    <p className="mb-0" style={{textTransform:"capitalize"}}>Preventative Maintenance</p>
                  </Link>
                  <Link to={"/digital-signage-solution"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenServices(false); setMenuState(false)}}>
                    <p className="mb-0" style={{textTransform:"capitalize"}}>Digital Signage Solutions</p>
                  </Link>
                  <Link to={"/everything-audio"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenServices(false); setMenuState(false)}}>
                    <p className="mb-0" style={{textTransform:"capitalize"}}>Audio Installations</p>
                  </Link>
                  <Link to={"/network-design"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenServices(false); setMenuState(false)}}>
                    <p className="mb-0" style={{textTransform:"capitalize"}}>Network Design</p>
                  </Link>
                  <Link to={"/structured-wiring-perfected"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenServices(false); setMenuState(false)}}>
                    <p className="mb-0" style={{textTransform:"capitalize"}}>Structured Wiring</p>
                  </Link>
                  <Link to={"/logistics-procurement"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenServices(false); setMenuState(false)}}>
                    <p className="mb-0" style={{textTransform:"capitalize"}}>LPWP</p>
                  </Link>
                  <Link to={"/environment-surveys"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenServices(false); setMenuState(false)}}>
                    <p className="mb-0" style={{textTransform:"capitalize"}}>Surveys and Data Insights</p>
                  </Link>
                  <Link to={"/remote-monitoring-support"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenServices(false); setMenuState(false)}}>
                    <p className="mb-0" style={{textTransform:"capitalize"}}>Remote Monitoring </p>
                  </Link>
                 
                  </div>
                </div>
                </li>
              <li>
                <Link to={"/call-center-support-hub-support"} className="nav-links font-bebus" onClick={()=>{setMenuState(false)}}>
                Support Hub
                </Link>
              </li>
              <li>
                <Link to={"/client"} className="nav-links font-bebus" onClick={()=>{setMenuState(false)}}>
                Clients
                </Link>
              </li>
              
              <li>

                <div className="navigation-dropdown">
                <div className="dropdown-trigger d-flex align-items-center" style={{color:"black"}} onClick={onClickService} >
                      <a className="nav-links nav-txt" >Resources</a>
                      <img src='/images/icons/drop-down-icon-b.png' className={isDropdownOpen ? "rotate-icon arrow-icon":"rotate-back arrow-icon" } />
                  </div>
                  <div className={`dropdown-content ${isDropdownOpen ? 'open' : 'd-none'}`} style={{top:"85%",left:"0%"}}>
                    
                  {/* <Link to={"/case-studies"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                    <p className="mb-0" style={{textTransform:"capitalize"}}>Case Studies</p>
                  </Link> */}
                  <Link to={"/blog"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                    <p className="mb-0" style={{textTransform:"capitalize"}}>Blogs</p>
                  </Link>
                  <Link to={"/careers"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                    <p className="mb-0" style={{textTransform:"capitalize"}}>Careers</p>
                  </Link>
                  </div>
                </div>
                </li>
             
              <li>
              <div className="navigation-dropdown">
                <div className="dropdown-trigger d-flex align-items-center" style={{color:"black"}} onClick={onClickContact} >
                      <a className="nav-links nav-txt" >Contact</a>
                      <img src='/images/icons/drop-down-icon-b.png' className={isDropdownOpenContact ? "rotate-icon arrow-icon":"rotate-back arrow-icon" } />
                  </div>
                  <div className={`dropdown-content ${isDropdownOpenContact ? 'open' : 'd-none'}`} style={{top:"85%",left:"0%"}}>
                    
                  <Link to={"/contact-usa"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenContact(false); setMenuState(false)}}>
                    <p className="mb-0" style={{textTransform:"capitalize"}}>USA</p>
                  </Link>
                  <Link to={"/contact"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenContact(false); setMenuState(false)}}>
                    <p className="mb-0" style={{textTransform:"capitalize"}}>Canda</p>
                  </Link>
                  </div>
                </div>
              </li>
              <li>
                <div className="nav-buttton mt-3 d-flex flex-column align-items-start gap-3 extras-btns">
                  <a
                     href="mailto:sales@bluefysh.com,info@bluefysh.com"
                     style={{textDecoration:"none"}}
                   
                  >
                <div onClick={()=>{navigate("/contact")}}><button className="blue-btn">
                  Get Started
                </button></div>
                </a>
                {/* <button className="get-quote-btn" onClick={()=>{setIsDropdownOpen(false);setIsDropdownOpenDesk(false);navigate("/contact")}}>GET A QUOTE</button> */}
                </div>
              </li>
            </ul>
          </nav>
        </nav>
      </div>
    </div>
  );
};

export default Header; 