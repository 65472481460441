import React from "react";
import "./ImgByContent.css"; 
import { Link,useNavigate } from 'react-router-dom';

const ImgByContent = ({
  Paragraph,
  subHeading,
  Heading,
  HighlightedHeading,
  HighlightedHeadingBefore,
  shiftRow = false,
  btnText=null,
  href="#",
  imgLink="",
  bgImage=null,
  bgTransparent=false
}) => {
  const paragraphs = Paragraph.split('\n').map((paragraph, index) => <p key={index} className='body-paragraph my-1 mb-3'>{paragraph}</p>);
  const navigate = useNavigate()

  return (
    <div className="img_by_cn_section" style={bgImage==null? bgTransparent?{background:"transparent"}:{background: "#022A44" }:{backgroundImage:`url(${bgImage})`}}>
      <div className="container">
        <div
          className={`row d-flex ${
            shiftRow ? "flex-row-reverse" : ""
          } align-items-center justify-content-center ${shiftRow && "px-2"}`}
        >
          <div className="col-lg-6 col-md-12 p-0 m-0 d-flex align-items-center justify-content-center">
            
              <div className="image-wrapper ">
                <img className="img-fluid" src={imgLink} />
              </div>
          </div>

          <div className="col-lg-6 p-0 text-lg-start text-center col-md-12 mt-2 m-0 px-2" >
            <div className="img-cn-subheading mt-lg-0 mt-4">
              <h3 className="sub-heading">{subHeading}</h3>
            </div>
            <div className="img-cn-heading">
              <h2 className="body-heading">
              <span className="color-blue">{HighlightedHeadingBefore}</span>
                {Heading} <span className="color-blue">{HighlightedHeading}</span>
              </h2>
            </div>
            <div className={`about-paragraph `}> 
              <p className="body-paragraph mx-lg-0 mx-auto" style={{maxWidth:"90%"}}>{paragraphs}</p>
            </div>
            {/* {
              btnText &&
              <div className="action-triggerer-btn mt-4">
                {
                href == "tel"?
                <a href="tel:925 832 0920"><button className="blue-btn">{btnText}</button></a>
                :
                <button className="blue-btn" onClick={()=>{navigate(href)}}>{btnText}</button>
              }
              </div>
            } */}
            <div className="action-triggerer-btn mt-4">
                <a href="mailto:sales@bluefysh.com,info@bluefysh.com"><button className="blue-btn">Get Started</button></a>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImgByContent;