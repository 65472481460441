import React,{useState} from 'react'
import "./TextCard.css"
import TextCardBox from './TextCardBox'

const cardContent = [
    {
        title:"OUR MISSION",
        iconImg:"/images/icons/mission-card.svg",
        breifPara:"At Blue Fysh, we excel in servicing and installing & maintaining digital signage,..",
        actualPara:"At Blue Fysh, we excel in servicing and installing & maintaining digital signage, IT networks, and audio systems. Our skilled labor technicians are the foundation of our success, providing top-quality service that ensures client satisfaction and operational excellence.",
        listPoint:null
    },
    {
        title:"OUR VISION",
        iconImg:"/images/icons/vision-card.svg",
        breifPara:"We create & implement digital and  networking solutions that connect people to brands...",
        actualPara:"We create & implement digital and  networking solutions that connect people to brands in the places they live, love and work.",
        listPoint:null
    },
    {
        title:"OUR CORE VALUES",
        iconImg:"/images/icons/value-card.svg",
        breifPara:"",
        actualPara:"",
        listPoint :[
            "We are passionate.",
            "Creative problem solving is a key competency.",
            "Integrity is a key foundation.",
            "Quality is a hallmark of our work.",
            "We are  a collaborative team.",
            "Innovation is key to our success.",
            "We are customer focused."
        ]
    },
]

function TextCard() {
  return (
    <div className='text-card-container standard-padding-space'>
        <div className='container '>
            
            <div className='container-fluid mt-5'>
                <div className='row gy-3'>
                    {
                        cardContent.map((ele)=>{
                            return(
                            <div className='col-lg-4 '>
                                <TextCardBox
                                    title={ele.title}
                                    iconImg={ele.iconImg}
                                    breifPara={ele.breifPara}
                                    actualPara={ele.actualPara}
                                    listPoint={ele.listPoint}
                                />
                            </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

export default TextCard