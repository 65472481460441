import React, { Fragment } from "react";
import HeroBanner from "../../components/Common Banner Component/HeroBanner";
import Testimonials from '../../components/Testimonials/Testimonials'; 
import TextContent from "../../components/TextContent/TextContent";
import ServicesCarasoul from "../../components/ServicesCarasoul/ServicesCarasoul";

const listData = [
    "Network Design: Tailored networks designed to meet specific business needs.",
    "Procurement: Sourcing of the best hardware and software for your network.",
    "Configuration: Pre-delivery equipment configuration to meet client requirements.",
    "Delivery & Installation: Direct site delivery with local technicians installing and conducting remote setup tests.",
    "Preventative Break-Fix: Designed to avoid down time.",
    "Environment surveys and data management: Enabling system wide data on your POS/Digital environment enterprise wide 24/7."
]


const cardCarouselData= [
    {
        heading:"Proactive Peace of Mind",
        content:"Transition from reactive to proactive with our Network Services, ensuring your systems run smoothly without the unpredictability of the break/fix model."
    },
    {
        heading:"Predictable Maintenance Budgeting",
        content:"Embrace the clarity of fixed costs and budgeting, avoiding the financial roller coaster that break/fix services can cause."
    },
    {
        heading:"Enhanced Infrastructure Investment",
        content:"Benefit from our commitment to optimizing your digital and network, rather than patching up problems temporarily like traditional break/fix providers."
    },
    {
        heading:"Guaranteed Service Excellence",
        content:"Depend on our Managed Services to deliver timely, expert support with a clear Service Level Agreement, eliminating the uncertainty of break/fix response times."
    },
    {
        heading:"Efficiency and Savings",
        content:"Revolutionize your tech support with our system designed to improve response times, enable real-time data analytics, and reduce costs through proactive management."
    },
    {
        heading:"Unified Approach",
        content:"Say goodbye to fragmented support and welcome unified data reporting and enhanced decision-making across your franchise network."
    },
    {
        heading:"Maintenance & Monitoring",
        content:"Benefit from our bi-annual maintenance checks, store audits, and early warning systems to pre-emptively address tech issues."
    },
    {
        heading:"Cost Transparency",
        content:"Enjoy transparent pricing and simplified monthly billing from Head Office, without hidden costs and markups."
    },
    {
        heading:"Quick, Reliable Service",
        content:"Our comprehensive support includes a robust POS system, prioritized service, and a flat-rate billing model for ultimate convenience and cost-effectiveness."
    },
]

function PreventativeMaintenance() {
  return (
    <div style={{background:"#022A44"}}>  
    <HeroBanner
      Paragraph={`Manage your network environment your way`}
      Heading={`Preventative Maintenance &`}
      HighlightedSpan={" Break-Fix"}
      bgImg={'/images/creatives/pm-hero-bg.jpg'}
      isChevron={true}
      isBtn={false}
    />
    
        {/* <div className="container pt-5 pb-4" >
            <div className="text-center">
                <h3 className="body-heading" style={{textTransform:"uppercase"}}>
                End to end managed services for 
                <br></br>
                <span className="body-heading color-blue">seamless business operations</span>
                </h3>

            </div>
        </div> */}
    <TextContent
        heading = "Choose Break Fix or "
        spanHeading="Proactive Maintenance"
        shiftRow={true}
        content={"Depending on your organization of needs you can engage Blue Fysh for break-fix ( solution based technician visit based on a specific issue or need to fix a break in your system) or engage us in a preventive maintenance program where your costs are predictable monthly payments and location visits are proactive assuring that the system is maintained and should not break resulting in increased uptime and lower overall costs.  If a break occurs on one of our Proactive Maintenance service partners the downtime is limited by adding break-fix based on priority level."}
        img={"/images/creatives/pm-text-content-1.webp"}
        mobileTextCenter={true}
    />
    {/* <TextContent
        heading = "Our holistic "
        spanHeading="approach"
        shiftRow={true}
        content={""}
        img={"/images/creatives/pm-list-content.png"}
        mobileTextCenter={true}
        listPoint={listData}
        listPointList={true}
    /> */}

    <ServicesCarasoul
        heading="The Benefits of the Blue Fysh Proactive"
        spanHeading=" Maintenance Program"
        serviceData={cardCarouselData}
    />


     
  </div>
  )
}

export default PreventativeMaintenance