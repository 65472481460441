import React,{Fragment} from 'react';
import HeroBanner from "../components/Common Banner Component/HeroBanner";
import CardBlog from '../components/CardBlog/CardBlog';
import Testimonials from '../components/Testimonials/Testimonials'; 
import ServicesCardList from '../components/ServicesCardList/ServicesCardList';

function Services() {
  return (
    <Fragment>
        <HeroBanner
            Paragraph={`We implement digital  networking solutions that connect people to brands in the places they live, love and work`}
            Heading={`OUR`}
            HighlightedSpan={" SERVICES"}
            
            isBtn={false}
            isChevron={true}
            bgImg={'/images/creatives/service-hero-bg.webp'}
        />
        <ServicesCardList/>
       
        <CardBlog />
    </Fragment>
  )
}

export default Services