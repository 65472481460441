import "./PartnerCompanies.css"
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";

const partnerImages = [
  "/images/icons/partner-logo-1.webp",
  "/images/icons/partner-logo-2.webp",
  "/images/icons/partner-logo-3.webp",
  "/images/icons/partner-logo-4.webp",
  "/images/icons/partner-logo-5.webp",
  "/images/icons/partner-logo-6.webp",
  "/images/icons/partner-logo-7.webp",
  "/images/icons/partner-logo-8.webp",
  "/images/icons/partner-logo-9.webp",
  "/images/icons/partner-logo-10.webp",
  "/images/icons/partner-logo-11.webp",
  "/images/icons/partner-logo-12.webp",
  "/images/icons/partner-logo-13.webp",
  "/images/icons/partner-logo-14.webp",
  "/images/icons/partner-logo-15.webp",
  "/images/icons/partner-logo-16.webp",
  "/images/icons/partner-logo-17.webp",
  // "/images/icons/partner-logo-18.webp",
]

function PartnerCompanies({
  increasedUpperSpace= false,
  upperSpaceRemove = false,
  transparentBg=false
}) {
  return (
    <div className={`partnerCompanies_container ${increasedUpperSpace && "pt-5"}`}>
        <div className={`companies-logo-box ${upperSpaceRemove && "pt-2"} ${increasedUpperSpace && "mt-3"}`} style={transparentBg ? {background:"transparent"}:{}}>
          <div className="container">
          <Swiper
            spaceBetween={50}
            autoplay={{
              delay: 1500,
              disableOnInteraction: false,
            }}
            loop={true}
            modules={[Autoplay]}
            breakpoints={{
                200:{
                  slidesPerView: 1,
                },
                550: {
                  slidesPerView: 2,
                },
                800: {
                  slidesPerView: 3,
                },
                1200: {
                  slidesPerView: 4,
                },
                1400: {
                  slidesPerView: 5,
                }
              }}
            className=" py-3 mx-auto"
            >
              {
                partnerImages.map((ele)=>{
                  return(
                  <SwiperSlide>
                  <img src={ele} alt="partner company's logo" className="company-icon" />
                  </SwiperSlide>
                  )
                })
              }
        </Swiper>
          </div>
        </div>
    </div>
  )
}

export default PartnerCompanies