import React,{useState , useEffect} from "react";
import "./CardBlog.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from "swiper/modules";
import CardBox from "../Genral Components/CardBox";
import {useNavigate} from "react-router-dom"
import axios from "axios"

let defaultBlogObj;

function CardTestimonial() {

  const blogData = [
    {
      cardImg: "./images/creatives/blog-thumb2.jpg",
      title: "Unforgettable Shopping Experiences",
      date: "19, August, 23",
    },
    {
      cardImg: "./images/creatives/blog-thumb3.jpg",
      title: "The Digital Signage Power Play",
      date: "19, August, 23",
    },
    {
      cardImg: "./images/creatives/blog-thumb1.jpg",
      title: "Wireless Power for Signage",
      date: "19, August, 23",
    },
  ];

  const [blogs, setBlogs] = useState([]);
  const [recentBlogs, setRecentBlogs] = useState([]);

  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  let blogAPICalled = false;

  useEffect(() => {
    getAllBlogsListing();
  }, []);

  const getAllBlogsListing = () => {
    if (blogAPICalled) return;
    blogAPICalled = true;

    setLoading(true);
    axios
      .get("/blogs", {})
      .then((res) => {
        if (res.data.status === "success") {
          let Updated_data_Blogs = [];
          let Updated_recent_blogs = [];
          res.data.data.forEach((item) => {
            Updated_data_Blogs.push({
              id: item.blog_id,
              main_heading: item.title,
              paragraph: item.brief_paragraph,
              logo: "/favicon.png",
              entity: "Creative Squad",
              date: item.published_date,
              slug_url: item.slug_url,
              category: item.category,
              blog_creative: item.blog_image,
            });
          });
          res.data.data.forEach((item) => {
            Updated_recent_blogs.push({
              id: item.blog_id,
              slug_url: item.slug_url,
              logo: item.blog_image,
              blog_description: item.title,
              date: item.published_date,
            });
          });
          setRecentBlogs(Updated_recent_blogs.slice(0, 3));
          setBlogs(Updated_data_Blogs);
          if(blogs.length <= 3)
          {
            setBlogs((prev)=>[...prev,...prev])
          }
          defaultBlogObj = Updated_data_Blogs;
          setLoading(false);
        }
          
      })
      .catch((err) => {
        console.log("eror =>",err);
        setLoading(false);
      });
  };

  const convertToSlug = (str) => {
    return str.toLowerCase().replace(/\s+/g, "-");
  };

  const redirectUserToBlog = (id) => {
    if (!id) return;
    let slug = convertToSlug(id);

    navigate(`/blogs/${slug}`);
  };

  return (
    <div className='CardTestimonial-container '>
      <div className="container">

        <div className="testimonial-text-content ">
            <h3 className="body-heading">LATEST FROM <span className="body-heading color-blue">OUR BLOGS</span></h3>   
        </div>

        <Swiper
            spaceBetween={50}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            loop={true}
            modules={[Autoplay]}
            breakpoints={{
                280: {
                  slidesPerView: 1,
                },
                992: {
                  slidesPerView: 3,
                }
              }}
            className="card-list"
            >
            {blogs.map((blogData,ind)=>{
              return (
                <>
                  <SwiperSlide key={ind}>
                    <CardBox
                      cardImg={blogData.blog_creative}
                      title={blogData.main_heading}
                      date={blogData.date}
                      slug={blogData.slug_url}
                    />
                  </SwiperSlide>
                </>
              );
            })}
            
        </Swiper>
        <div className="btn-container d-flex align-items-center justify-content-center">
          <button className="blue-btn" onClick={()=>{navigate("/blog")}}>VIEW ALL</button>
        </div>
      </div>
    </div>
  )
}

export default CardTestimonial