import React,{useState,useEffect} from "react";
import "./HeroBanner.css";
import axios from "axios"
import {Spinner} from "react-bootstrap"
import { useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import PartnerCompanies from "../PartnerCompanies/PartnerCompanies";

const HeroBanner = ({
  Heading,
  homeHeading=null,
  HighlightedSpan,
  headingBreak=false,
  preSpanHeading,
  whiteHiglightedText=null,
  blueHiglightedText=null,
  Paragraph,
  btnText,
  iconURL,
  isChevron=false,
  isBtn=!isChevron,
  bgImg,
  btnColor,
  bgVideo=false,
  serviceForm=false,
  socialLinks=false,
  textStart=false,
  compressedHeading=false,
  bgEffect=true,
  offsetStyle=false,
  spanHeadingBr=false,
  contactDetails={},
  partnerCompanies=false
}) => {

  const [offsetX, setOffsetX] = useState("");
  const [offsetY, setOffsetY] = useState("");
  const [screenWidth,setScreenWidth] = useState(window.innerWidth)
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()
  const friction = 1 / 32;

  const _mouseMove = (e) => {
    let followX = window.innerWidth / 2 - e.clientX;
    let followY = window.innerHeight / 2 - e.clientY;

    let x = 0;
    let y = 0;
    x += (-followX - x) * friction;
    y += (followY - y) * friction;

    setOffsetX(x);
    setOffsetY(y);
  };

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    // document.addEventListener("mousemove", _mouseMove);
    // handleResize();
    // window.addEventListener('resize', handleResize);
    // return () => {
    //   document.removeEventListener("mousemove", _mouseMove);
    //   window.removeEventListener('resize', handleResize);
    // };
  }, []);
  const offset = {
    transform: ` perspective(600px)
      rotateY(${offsetX}deg)
      rotateX(${offsetY}deg)`,
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(`${contactDetails.formRoute}`, {
        email: email,
        name:name,
        message:message,
        phoneNum:phone,
        formType: "Contact page Form",
      })
      .then((response) => {
        if (response.data.msg === "success") {
          setError(false);
          setSuccess("Message sent Successfully!");
          setLoading(false);
          // setEmail("");
          // window.open("/thank-you", '_blank');
        } else if (response.data.msg === "fail") {
          setSuccess(false);
          setError("Failed to send message, try again by reloading the page.");
          setLoading(false);
          setEmail("");
        }
      });
  };
  
  const handlePhoneChange = (e) => {
    const numericValue = e.target.value.replace(/\D/g, ''); 
    const maxLength = 25;


    if (numericValue.length <= maxLength) {
      setPhone(numericValue);
    }
  };
  const handleNameChange = (e) => {
    const inputValue = e.target.value;
    const regex = /^[a-zA-Z\s]*$/;

    if (regex.test(inputValue) || inputValue === '') {
      setName(inputValue);
    }
  };

  return (
    <>
    <div className={`common_hero_section ${bgVideo && "bg-video"}  ${socialLinks && "hero-social-links-height"}`} style={{backgroundImage: `url(${bgImg})`}}>
    <Header/> 
      { bgVideo && <video autoPlay loop muted>
        <source src="./images/backgroundVideo.mp4" type="video/mp4" />
      </video>}
      {bgEffect && <div className="bg-effect"></div>}

<div className={`common_hero_section ${bgVideo && "bg-video"} ${socialLinks && "hero-social-links-height"} py-2 d-flex flex-column justify-content-center align-items-center`}>

      <div className={`hero_content_wrapper w-100`} >
      
      
        <div className="container ">
          <div className="row gy-5 d-flex align-items-center ">
            <div className={serviceForm ? "col-md-6" : "col-12"}>
            <div className="hero-text-content" style={offsetStyle && screenWidth >=768 ? offset:{}}>
                <h3 className={`hero-section-heading ${compressedHeading && "w-75"} ${homeHeading!==null && "font-scrible"}`} style={textStart ? {textAlign:"start"} : {}}>
                  <span className="color-blue">{preSpanHeading} {headingBreak && <br></br>}</span>{Heading} {spanHeadingBr && <br></br>}<span className={`${homeHeading!==null && "font-scrible"} color-blue`} >{HighlightedSpan}</span>
                </h3>
                {
                  homeHeading!== null &&
                  <h3 className="hero-section-heading">{homeHeading}</h3>
                }
                {
                  blueHiglightedText!== null &&
                  <h3 className="sub-heading color-blue" style={textStart ? {textAlign:"start",alignSelf:"start"} : {}}>{blueHiglightedText}</h3>
                }
                {
                  whiteHiglightedText!== null &&
                  <h3 className={`body-paragraph color-white ${!serviceForm && "large-para"}  ${serviceForm && "color-white"} `} style={textStart ? {textAlign:"start",alignSelf:"start"} : {}}>{whiteHiglightedText}</h3>
                }
                <p className={`body-paragraph color-white ${!serviceForm && "large-para"} ${serviceForm && "color-white"} `} style={textStart ? {textAlign:"start",alignSelf:"start"} : {}}>{Paragraph}</p>
                {isChevron && <img src="/images/icons/hero-cheveroon.svg" />}
                {isBtn && 
                  <div className="action-video-play-trigger">
                    <button className="blue-btn">
                      <span>
                        <img src={iconURL} className="img-fluid" />
                      </span>
                      {btnText}
                    </button>
                  </div>
                }
                {partnerCompanies && <div className="d-xl-block d-none"><PartnerCompanies  upperSpaceRemove={true} increasedUpperSpace={true} transparentBg={true}/></div>}
                {socialLinks && (
                  <div className="social-links d-flex flex-column align-self-start gap-4">
                    <div className="d-flex align-items-lg-center flex-column flex-lg-row gap-4">
                      <div className="link-container d-flex align-items-center gap-2">
                        <img src="/images/icons/phone-icon.svg" />
                        <p className="m-0">{contactDetails.phone}</p>
                      </div>
                      <div className="link-container d-flex align-items-center gap-2">
                        <img src="/images/icons/mail-icon.svg" />
                        <p className="m-0">{contactDetails.mail}</p>
                      </div>
                    </div>
                    <div className="link-container d-flex align-items-center gap-2">
                      <img src="/images/icons/time-icon.svg" />
                      <p className="m-0">Hours: Monday – Friday 8am-5pm </p>
                    </div>
                    <div className="link-container d-flex align-items-start gap-2">
                      <img src="/images/icons/location-icon.svg" />
                      <p className="m-0">{contactDetails.location}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {serviceForm && 
              <div className="col-md-6">
                <form className="service-form d-flex flex-column p-4 py-5 mx-auto"
                  onSubmit={formSubmitHandler}
                >
                  <h3 className="body-heading" >Let’s get in <span >touch!</span></h3>
                  <p className="body-paragraph text-start color-white">Let’s discuss your project and find out what we can do!</p>
                  
                  <input 
                   type="text" 
                   value={name}
                   required
                   maxLength="36"
                   onChange={handleNameChange}
                  placeholder="Your Full Name" 
                />

                <input 
                   type="text" 
                   value={email}
                   required
                   maxLength="36"
                   onChange={(e) => setEmail(e.target.value)}
                  placeholder="Your Email Address" 
                />

                  <input 
                  type="tel" 
                  value={phone}
                  maxLength="25"
                  onChange={handlePhoneChange}
                  placeholder="Phone Number" 
                />
                  <input required 
                  type="text" 
                  value={message}
                  maxLength="350"
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Your Message" 
                  />
                  <button className="blue-btn mt-5" type="submit">Submit
                  {loading ? (
                      <Spinner
                          animation="border"
                          role="status"
                          style={{ color: "white" ,width:"15px",height:"15px",marginLeft:"10px"}}
                      />
                  ) : (
                  success ? (
                      <div>
                      {setLoading(false)}
                      {alert(success)}

                      {setSuccess(false)}
                      </div>
                  ) : (
                      error && (
                      <div>
                          {setLoading(false)}
                          {alert(error)}
                          {setError(false)}
                      </div>
                      )
                  )
                  )}
                  </button>
                </form>
              </div>}
          </div>
        </div>
      </div>
      
</div>
    </div>

    {partnerCompanies && <div className="d-xl-none d-block"><PartnerCompanies  upperSpaceRemove={true} /></div>}
    </>
  );
};

export default HeroBanner; 