import React,{useState} from 'react'
import "./GridCard.css"
import GridCardBox from "./GridCardBox"

const cardContent = [
    {
        title:"Strategic Sourcing for Your Unique Needs",
        breifPara:"Our expertise extends to the strategic sourcing of specialized materials for your projects. Whether it's mounting hardware, cabling solutions, fiber optics, audio equipment, panels, monitor",
        actualPara:"Our expertise extends to the strategic sourcing of specialized materials for your projects. Whether it's mounting hardware, cabling solutions, fiber optics, audio equipment, panels, monitor mounts, or any other critical components for your custom installations, we have the capacity and network to procure what you need. Our service scales with your requirements, from modest undertakings to significant capital projects, ensuring that every detail is accounted for."
    },
    {
        title:"Kitting and Nationwide Distribution",
        breifPara:"We understand that managing the logistics of equipment can be overwhelming. That's why we offer comprehensive kitting services to compile all your necessary",
        actualPara:"We understand that managing the logistics of equipment can be overwhelming. That's why we offer comprehensive kitting services to compile all your necessary equipment into ready-to-ship packages. Once prepared, we can distribute these kits from our national network of warehouses directly to your project site. This streamlined process alleviates the logistical burdens, allowing you to focus on the successful execution of your project."
    },
    {
        title:"Hassle-Free Logistics Management",
        breifPara:"We take pride in offering a hassle-free experience. For straightforward requests, simply fill out the form below, and we'll handle the rest. For more complex projects that demand a nuanced understanding and a detailed",
        actualPara:"We take pride in offering a hassle-free experience. For straightforward requests, simply fill out the form below, and we'll handle the rest. For more complex projects that demand a nuanced understanding and a detailed quote, our team is just a phone call away. We're dedicated to providing exceptional service that simplifies your procurement and logistics, ensuring that your projects are completed without a hitch."
    },
    {
        title:"Connect with Us for Customized Support",
        breifPara:"Whether you need a simple item or a comprehensive supply chain solution for complex projects, our team is ready to assist. Get in touch with us today, and let's discuss how we can support your logistics and",
        actualPara:"Whether you need a simple item or a comprehensive supply chain solution for complex projects, our team is ready to assist. Get in touch with us today, and let's discuss how we can support your logistics and procurement needs with precision, efficiency, and unwavering commitment to your success. Fill out our easy-to-use form or call us for a personalized quote. Let us take the strain out of supply chain management, so you can deliver your projects with confidence."
    },
]

function GridCard({
    heading="",
    spanHeading="",
    whiteContent="",
    content=""
}) {
  return (
    <div className='grid-card-container standard-padding-space'>
        <div className='container my-4'>

            <div className='text-content text-center'>
                <h3 className='body-heading'><span className='color-blue'>{spanHeading}</span>{heading}</h3>
                <p className='body-paragraph my-3' style={{color:"white",fontSize:"20px"}}>{whiteContent}</p>
                <p className='body-paragraph'>
                    {
                        content
                    }

                </p>
            </div>
            
            <div className='container-fluid mt-5'>
                <div className='row gy-4'>
                    {
                        cardContent.map((ele)=>{
                            return(
                            <div className='col-lg-6 '>
                                <GridCardBox
                                    title={ele.title}
                                    breifPara={ele.breifPara}
                                    actualPara={ele.actualPara}
                                />
                            </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

export default GridCard