import React, { Fragment } from "react";
import HeroBanner from "../../components/Common Banner Component/HeroBanner";
import Testimonials from '../../components/Testimonials/Testimonials'; 
import TextContent from "../../components/TextContent/TextContent";
import TextParalalaxEffect from "../../components/TextParalalaxEffect/TextParalalaxEffect";
import AboutParalalaxEffect from "../../components/AboutParalalaxEffect/AboutParalalaxEffect";
import ListCard from "../../components/ListCard/ListCard";

const list1Box = [
    {
        title:"Managed and Unmanaged Switches",
        iconImg:"/images/icons/nd-icon-1.png",
        content:[
            "Control traffic, prioritize apps.",
            "Unmanaged: Simple, cost-effective for small businesses.",
            "Cost Benefits; Save on small-scale network setup"
        ]
    },
    {
        title:"Routers & Bridges",
        iconImg:"/images/icons/nd-icon-2.png",
        content:[
            "Routers: Optimize data flow.",
            "Bridges: Segment networks, reduce traffic.",
            "Cost Benefits: Optimize network upgrades & cost"
        ]
    },
    {
        title:"Firewalls for Security",
        iconImg:"/images/icons/nd-icon-3.png",
        content:[
            "Protect internal network from external threats.",
            "Comply with data security regulations.",
            "Cost Benefit: Avoid breach costs, lower insurance premiums"
        ]
    },
    {
        title:"Backup Cellular Modems",
        iconImg:"/images/icons/nd-icon-4.png",
        content:[
            "Alternate internet connection for continuity.",
            "Keeps point-of-sale systems online during outages",
            "Cost Benefits: Prevent outages, reduce interruption costs"
        ]
    },
]
const list2Box = [
    {
        title:"Network Racks",
        iconImg:"/images/icons/nd-icon-5.png",
        content:[
            "Centralize equipment, simplify maintenance, optimize cooling.",
            "Cost Benefits: Extend hardware life, reduce maintenance"
        ]
    },
    {
        title:"Wireless Access Points",
        iconImg:"/images/icons/nd-icon-6.png",
        content:[
            "Extend network reach, improve mobility.",
            "Offer customer Wi-Fi, enhance experience.",
            "Cost Benefits: Cut cabling costs, increase worker productivity"
        ]
    },
    {
        title:"VPNs",
        iconImg:"/images/icons/nd-icon-7.png",
        content:[
            "Secure remote network access.",
            "Protect data on public networks.",
            "Cost Benefit: Less office space, save on travel"
        ]
    },
    {
        title:"Structured Cabling",
        iconImg:"/images/icons/nd-icon-8.png",
        content:[
            "Standardized wiring, easy troubleshooting.",
            "Support data, voice, video communications",
            "Cost Benefits: Minimize rewiring, Simplify network scaling"
        ]
    },
]

function NetworkDesign() {
    return (
        <div style={{background:"#022A44"}}>  
        <HeroBanner
            preSpanHeading={"Network"}
          Heading={` Design`}
          HighlightedSpan={""}
          Paragraph={``}
        //   Paragraph={`Lorem Ipsum is simply dummy text of the printing and typesetting industry.`}
          bgImg={'/images/creatives/nd-hero-bg.jpg'}
          isChevron={true}
          isBtn={false}
        />
        
        <TextContent
            preSpanHeading="Stay Connected With "
            heading = "The Right Design and Support"
            shiftRow={true}
            content={"Our IT network services are comprehensive, featuring design processes, dedicated program managers, and detailed deployment plans for hardware, IT infrastructure, signage software, training, and operational planning. "}
            img={"/images/creatives/nd-text-1.png"}
            mobileTextCenter={true}
            listPointList={false}
            btn={true}
            btnText={"Let's Connect"}
            btnRef="tel"
        />

        <ListCard
            preSpanHeading="Consulting and Professional "
            heading="Services"
            boxArr={list1Box}    
        />
        <ListCard
            preSpanHeading="Managed "
            heading="Services"
            boxArr={list2Box}    
        />

        <AboutParalalaxEffect/>
    
         
      </div>
      )
}

export default NetworkDesign