import "./PopularCardBlog.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from "swiper/modules";
import CardBox from "../Genral Components/CardBox";

function PopularCardBlog({
  recentBlogs=null,
}) {


  return (
    <div className='PopularCardBlog-container '>
      <div className="container">

        <div className="testimonial-text-content ">
            <h3 className="body-heading"><span className="body-heading color-blue">Most popular Blogs</span></h3>   
        </div>

        <Swiper
            spaceBetween={50}
            autoplay={{
              delay: 1500,
              disableOnInteraction: false,
            }}
            loop={true}
            // modules={[Autoplay]}
            breakpoints={{
                280: {
                  slidesPerView: 1,
                },
                992: {
                  slidesPerView: 3,
                }
              }}
            className="card-list"
            >
            {recentBlogs.map((blogData,ind)=>{
              return (<>
                <SwiperSlide key={ind}>
                  <CardBox 
                    cardImg={blogData.blog_creative} 
                    title={blogData.main_heading} 
                    date={blogData.date}
                    slug={blogData.slug_url}
                  />
                </SwiperSlide>
              </>)
            })}
            
        </Swiper>
        {/* <div className="btn-container d-flex align-items-center justify-content-center">
          <button className="blue-btn">VIEW ALL</button>
        </div> */}
      </div>
    </div>
  )
}

export default PopularCardBlog