import React from 'react'
import HeroBanner from "../components/Common Banner Component/HeroBanner";
import CaseStudiesList from '../components/CaseStudiesList/CaseStudiesList';

function CaseStudies() {
  return (
    <>
        <HeroBanner
            Paragraph={``}
            Heading={``}
            HighlightedSpan={"CASE STUDIES"}
            isBtn={false}
            bgImg={'/images/creatives/case_st_banner.jpg'}
            isChevron={true}
        />

        <CaseStudiesList/>
    </>
  )
}

export default CaseStudies