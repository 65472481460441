import React from 'react'
import "./ListCard.css"

function TextCardBox({
    title,
    iconImg,
    content,
}) {
  return (
    <div
      className="list-card-box p-4 px-5 d-flex flex-column align-items-start"
     
    >
      <div className="content-info d-flex gap-3 align-items-center justify-content-start my-4">
        <img src={iconImg} className="card-icon" />
        <h5 className="body-heading mb-0" style={{fontWeight:400 , fontSize:"23px"}}>{title}</h5>
      </div>
        {
          content.map((ele)=>{
            return(
              <div className='d-flex align-items-start my-2 icon-list gap-2'>
                  <img src='/images/icons/list-card-bullets.png' alt="icon" className='list-bullet'/>
                  <p className='body-paragraph my-0 list-para'>{ele}</p>
              </div>
            )
          })
        }
      
      
    </div>
  );
}

export default TextCardBox