import React, { Fragment } from "react";
import HeroBanner from "../../components/Common Banner Component/HeroBanner";
import Testimonials from '../../components/Testimonials/Testimonials'; 
import TextContent from "../../components/TextContent/TextContent";
import TextParalalaxEffect from "../../components/TextParalalaxEffect/TextParalalaxEffect";
import IconsCardCarasoul from "../../components/IconCardCarasoul/IconCardCarasoul";



const cardCarouselData= [
    {
        icon:"/images/icons/es-icon-1.png",
        heading:"Maintenance Reports",
        content:"These provide detailed insights into the network's performance, highlighting any areas that may require attention or improvement."
    },
    {
        icon:"/images/icons/es-icon-2.png",
        heading:"Restaurant Audits",
        content:"We compile reports on the condition and service history of equipment, along with technical recommendations to ensure optimal functionality."
    },
    {
        icon:"/images/icons/es-icon-3.png",
        heading:"Red Flag",
        content:"We identify any imminent tech failures that could severely impact operations, allowing clients to take preventive measures."
    },
    {
        icon:"/images/icons/es-icon-4.png",
        heading:"Store Rating System",
        content:"Using a 0-10 scale, we provide an immediate assessment of store conditions, making it easy for clients to understand the urgency of any issues."
    },
    {
        icon:"/images/icons/es-icon-5.png",
        heading:"State of the Network Review",
        content:"This report offers a clear and concise summary of the provincial network, helping clients to get a broad view of their infrastructure health."
    },
    {
        icon:"/images/icons/es-icon-6.png",
        heading:"Report System",
        content:"Our established system for generating reports and updating the database ensures that all information is current and accessible for informed decision-making and possible future budgets."
    },
    {
        icon:"/images/icons/es-icon-7.png",
        heading:"Head Office Insights",
        content:"Our reporting system allows the head office to view a snapshot of store health and service call activity, providing essential insights for strategic planning and resource allocation."
    },
    
]

function EnvironmentSurveys() {
    return (
        <div style={{background:"#022A44"}}>  
        <HeroBanner
          Paragraph={`Let us Empower You`}
          preSpanHeading={"Environment "}
          Heading={`Surveys and Data Insights`}
          bgImg={'/images/creatives/es-hero-bg.webp'}
          isChevron={true}
          isBtn={false}
        />
        
        <TextContent
            heading = "Environment Reports Delivered "
            spanHeading="With Insight"
            shiftRow={true}
            content={"Our team of highly trained technicians conducts comprehensive site surveys to assess the condition and functionality of a variety of equipment within a client's establishment. During the surveys, we focus on key areas such as:\n Indoor Digital Menu Boards, Point-of-Sale (POS) Systems, Network Racks, Outdoor Menu Boards and Speaker Posts, and Audio Equipment.\nFor each area, we collect information on the general setup and condition, including the model and serial numbers of equipment such as display units, kiosks, POS terminals, scanners, printers, modems, firewalls, LTE devices, amplifiers, speakers, and more. We also take note of any physical damages or maintenance issues that could affect performance."}
            img={"/images/creatives/es-text-1.png"}
            mobileTextCenter={true}
            listPointList={false}
            btn={true}
            btnText={"Let's Connect"}
            btnRef="tel"
        />
    
        <IconsCardCarasoul
            spanHeading="Insightful Reports "
            heading="for Clients"
            content="We turn the data collected from site surveys into a suite of detailed reports designed to give clients a clear understanding of their technical infrastructure and any actions needed:"
            serviceData={cardCarouselData}
        />
    
        <TextParalalaxEffect
            heading="Comprehensive Reports Helping "
            spanHeading="You Manage Your Business"
            headingBreak={false}
            content="We offer our clients a comprehensive picture of your technical environment, empowering you to maintain high standards of service and address any potential issues promptly."
            btn={false}
        />

         
      </div>
      )
}

export default EnvironmentSurveys