import React, { Fragment } from "react";
import HeroBanner from "../../components/Common Banner Component/HeroBanner";
import Testimonials from '../../components/Testimonials/Testimonials'; 
import TextContent from "../../components/TextContent/TextContent";
import TextParalalaxEffect from "../../components/TextParalalaxEffect/TextParalalaxEffect";


function DigitalSignageSolutions() {
    return (
        <div style={{background:"#022A44"}}>  
        <HeroBanner
          Paragraph={``}
          Heading={``}
          HighlightedSpan={"Digital Signage Solutions "}
          bgImg={'/images/creatives/dss-hero-bg.webp'}
          isChevron={true}
          isBtn={false}
        />
        
            <div className="container pt-5 pb-4" >
                <div className="text-center">
                    <h3 className="body-heading" style={{textTransform:"uppercase"}}>
                    Any Display, Any Size, Anywhere, All the time
                    <br></br>
                    <span className="body-heading color-blue"> Get your message across</span>
                    </h3>
    
                </div>
            </div>
        <TextContent
            heading = "Thousands of "
            spanHeading="installations"
            shiftRow={true}
            content={"All types of business channels. We understand the specific requirements of each channel, and how to help get your project completed and maintained. We provide focused integration and installation services that many end-to-end providers are not equipped to offer."}
            img={"/images/creatives/dss-text-1.webp"}
            mobileTextCenter={true}
            listPointList={false}
            btn={true}
            btnText={"Let's Connect"}
            btnRef="tel"
        />
        <TextContent
            heading = "Digital "
            spanHeading="Experts"
            shiftRow={false}
            content={"We have seen it all and know what works and what doesn’t. Our staff will consult with you on what products best to fit your objectives and budget, best placement for the most exposure, and visual attractiveness to maximize your returns. \n We provide focused integration and installation services that many end-to-end providers are not equipped to offer. Petroleum Retail, Fast Food (QSR), Hospitals, Grocery, Large Format & Boutique Retail, Call Centres, Government Agencies, Universities, Professional Offices, Financial Institutions, Hospitality, manufacturing and more."}
            img={"/images/creatives/dss-text-2.webp"}
            mobileTextCenter={true}
            listPointList={false}
            btn={false}
        />
    
        <TextParalalaxEffect
            heading="Experienced Employed "
            spanHeading="Technicians Nationwide"
            content="Whether it’s a digital drive through sign, wayfinding, kiosk, fixtures, shelf level projects, audience measurement systems, branding and experiential networks, video walls, advertising driven networks and projection systems we can help manage your project to success."
            btn={false}
            btnText="OUR CASE STUDIES"
            btnHref="#"
        />
    
         
      </div>
      )
}

export default DigitalSignageSolutions