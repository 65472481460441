import React, { Fragment } from "react";
import HeroBanner from "../../components/Common Banner Component/HeroBanner";
import Testimonials from '../../components/Testimonials/Testimonials'; 
import TextContent from "../../components/TextContent/TextContent";
import TextParalalaxEffect from "../../components/TextParalalaxEffect/TextParalalaxEffect";



function StructuredWiringPerfected() {
    return (
        <div style={{background:"#022A44"}}>  
        <HeroBanner
            preSpanHeading={""}
          Heading={"Structured "}
          HighlightedSpan={"Wiring Perfected"}
          Paragraph={`Connecting all things audio and visual.`}
          bgImg={'/images/creatives/swp-hero-bg.jpg'}
          isChevron={true}
          isBtn={false}
        />
        
        <TextContent
            preSpanHeading="Let’s Get "
            heading = "Wired"
            shiftRow={true}
            content={"Structured wiring is the highway that connects all things visual and audio. It needs to be planned thoughtfully, properly installed and you need a partner on call if anything needs changed, upgraded or serviced.\nWhether its one-off cabling projects to ongoing network infrastructure support. Our accredited technicians and industry-leading expertise give you total reassurance across a comprehensive range of installations."}
            img={"/images/creatives/swp-text-1.png"}
            mobileTextCenter={true}
            listPointList={false}
            btn={true}
            btnText={"Let's Connect"}
            btnRef="tel"
        />
        
        <TextContent
            preSpanHeading=""
            heading = "Connecting all things "
            spanHeading={"audio and visual."}
            shiftRow={false}
            content={"One-off cabling project? …ongoing network infrastructure support? …comprehensive installation? It’s no problem, our accredited technicians and industry-leading experts will ensure that your project is completed to perfection across a number of types of installations:"}
            img={"/images/creatives/swp-text-2.png"}
            mobileTextCenter={true}
            bulltePointList={true}
            bulletPoint={[
                "Single and multi-site solutions design and installation",
                "Contract work and retained service voice and data projects.",
                "Cat5e, Cat6, Cat6a, Cat7 specifications fibre optics",
                "Wireless access points, where structured wiring is cost prohibitive."
            ]}
            btn={false}
            postBtn={true}
            btnText={"Let's Connect"}
            btnRef="tel"
            afterListContent="We have an unparalleled commitment to implementing the best design to deliver the foremost performance for your cabling needs. We keep budget and schedules in line and disruption to a minimum."
        />

       

        <TextParalalaxEffect
            heading="Call Us Now For A "
            spanHeading="Free Consultation"
            content="All our work is guaranteed, and our systems and components have a full manufacturer’s warranty. Call us now to arrange your free consultation and infrastructure site audit."
            btn={false}
            btnText="GET FREE consultation"
            btnHref="tel"
            headingBreak={false}
        />
    
         
      </div>
      )
}

export default StructuredWiringPerfected