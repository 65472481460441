
function TestimonialCard(props) {
  return (
    <div className="testimonial-card py-5">
        <img className="quote-img" src="/images/icons/test-card-quote.svg" alt="quotation design icon" />
        <p className="pt-3 pt-md-0 ">{props.clientReview}</p>
        <div className="profile-info mt-auto pt-2 pt-md-0 d-flex flex-column flex-md-row text-center align-items-center ">
            <img src={props.clientImg} className="user-img mt-2"/>
            <h5  className="mb-0 mt-1">{props.clientName}</h5>
            {/* <img src="/images/icons/rating.svg" className="rating-img"/> */}
        </div>
    </div>
  )
}

export default TestimonialCard