import React from 'react'
import "./IconCardCarasoul.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import {Autoplay} from "swiper/modules"
import ServiceCard from './IconCard';

function IconsCardCarasoul({
    heading="",
    spanHeading="",
    content="",
    serviceData=[],
    marginTopRemoval=false
}) {



    const handlePrevButtonClick = () => {
        const swiperInstance = document.querySelector('#icon-service-cards-slider').swiper;
        swiperInstance.slidePrev();
      };
      
      const handleNextButtonClick = () => {
        const swiperInstance = document.querySelector('#icon-service-cards-slider').swiper;
        swiperInstance.slideNext();
      };
  return (
    <div className='IconCardCarasoul-container standard-padding-space' 
        style={marginTopRemoval? {paddingTop:"0"}:{}}
    >
        <div className='container'>

            <div className='text-content text-center mb-3'>

                <h3 className='body-heading text-center mb-0'><span className='color-blue'>{spanHeading}</span>{heading}</h3>
                <p className='body-paragraph mx-auto my-3' style={{width:"90%"}}>{content} </p>
            </div>

                <Swiper
                    spaceBetween={50}
                    autoplay={{
                    delay: 1500,
                    disableOnInteraction: false,
                    }}
                    loop={true}
                    modules={[Autoplay]}
                    navigation={
                        {
                          nextEl: '.swiper-button-next',
                          prevEl: '.swiper-button-prev',
                        }
                        }
                    breakpoints={{
                        200:{
                        slidesPerView: 1,
                        },
                        1100: {
                        slidesPerView: 3,
                        }
                    }}
                    pagination={{ clickable: true }}
                    observer ={true}
                    observeParents={true}
                    parallax={true}
                    className=" my-5 px-3 px-md-5 py-4"
                    style={{position:"relative"}}
                    id="icon-service-cards-slider"
                    >
                    
                    
                <div className="swiper-button-prev swiper-button d-lg-block d-none" onClick={handlePrevButtonClick}> 
                <img src="/images/icons/pm-carousel-arrow-left.png" className="arrow-img"/>
                </div>
                {
                        serviceData.map((ele,ind)=>{
                        return(<>
                            <SwiperSlide>
                                <ServiceCard
                                    icon={ele.icon}
                                    heading={ele.heading}
                                    content={ele.content}
                                />
                            </SwiperSlide>
        
                            </>)
                        })
                    }
                    <div className="swiper-button-next swiper-button d-lg-block d-none" onClick={handleNextButtonClick}>
                    <img src="/images/icons/pm-carousel-arrow-right.png" className="arrow-img"/>
                    </div>
                </Swiper>
                </div>
    </div>

  )
}

export default IconsCardCarasoul