import React,{useState} from 'react'
import "./CarrersForm.css"
import axios from 'axios'
import { Spinner } from 'react-bootstrap'

function CarrersForm() {
    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const [phone, setPhone] = useState()
    const [message, setMessage] = useState()
    const [cv,setCv] = useState(null)
    const [uploading,setUploading] = useState(false)
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const handlePhoneChange = (e) => {
        const numericValue = e.target.value.replace(/\D/g, ''); 
        const maxLength = 25;
    
    
        if (numericValue.length <= maxLength) {
          setPhone(numericValue);
        }
      };
      const cvFileHandler = async (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("cv", file);
        setUploading(true);
        try {
          const config = { Headers: { "Content-Type": "multipart/form-data" } };
          const { data } = await axios.post("/api/upload/resume", formData, config);
          setCv(data)
          setUploading(false);
        } catch (error) {
          console.error(error);
          setCv(null)
          e.target.value=""
          alert("error occured while uploading your file please make sure it's type is (.pdf or .docx) and is less then 3MB")
          setUploading(false);
        }
      };

      const submitCareerForm = (e) => {
        e.preventDefault();
        setLoading(true);
        if(cv === null)
        {
            setLoading(false)
            alert("Please upload your Resume file")
            return
        }
        axios
          .post(`/job-opportunity-form`, {
            email: email,
            name:name,
            message:message,
            phoneNum:phone,
            resume:cv,
            formType: "job Form",
          })
          .then((response) => {
            if (response.data.msg === "success") {
              setError(false);
              setSuccess("Message sent Successfully!");
              setLoading(false);
              // setEmail("");
              // window.open("/thank-you", '_blank');
            } else if (response.data.msg === "fail") {
              setSuccess(false);
              setError("Failed to send message, try again by reloading the page.");
              setLoading(false);
              setEmail("");
            }
          });
      };
  return (
    <div className='CarrersForm-container standard-padding-space'>
        <form className='container' onSubmit={submitCareerForm}>
            
              <div className="service-form d-flex flex-column p-4 py-5 mx-auto">
                <div className='text-center'>
                    <h3 className='body-heading'>Fill Out The Form <span className='color-blue'>Below</span></h3>
                    <h4 style={{color:"white"}}>We will be back to you</h4>
                </div>
              <input 
                   type="text" 
                   value={name}
                   required
                   maxLength="36"
                   onChange={(e) => setName(e.target.value)}
                  placeholder="Your Full Name" 
                />

                <input 
                   type="text" 
                   value={email}
                   required
                   maxLength="36"
                   onChange={(e) => setEmail(e.target.value)}
                  placeholder="Your Email Address" 
                />

                  <input 
                  type="tel" 
                  value={phone}
                  maxLength="25"
                  onChange={handlePhoneChange}
                  placeholder="Phone Number" 
                />
                
                
                  
                  <input required 
                  type="text" 
                  value={message}
                  maxLength="350"
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Your Message" 
                  className='message-box'
                  />
                  <div className="w-100 text-center mt-4">
                    <label class="file-input-wrapper mx-auto">
                      <input type="file" id="fileInput" onChange={cvFileHandler} />
                      Upload Resume
                    </label>
                  </div>
                

                <button className="blue-btn mt-5" type='submit'>Submit
                {loading ? (
                      <Spinner
                          animation="border"
                          role="status"
                          style={{ color: "white" ,width:"15px",height:"15px",marginLeft:"10px"}}
                      />
                  ) : (
                  success ? (
                      <div>
                      {setLoading(false)}
                      {alert(success)}

                      {setSuccess(false)}
                      </div>
                  ) : (
                      error && (
                      <div>
                          {setLoading(false)}
                          {alert(error)}
                          {setError(false)}
                      </div>
                      )
                  )
                  )}
                </button>
              </div>

        </form>
    </div>
  )
}

export default CarrersForm