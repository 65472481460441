import React from 'react'


function ServiceCard({
  icon,
    heading,
    content,
}) {
  const paragraphs = content.split('\n').map((paragraph, index) => <p key={index} className='body-paragraph my-1'>{paragraph}</p>);
  return (
    <div className="service-card py-4">
    <div className="service-card-info">
      <div className='d-flex align-item-center justify-content-center py-3'>
        <img src={icon} className='icon-card-icon'/>
      </div>
      <div className="profile-info px-2 d-flex flex-column gap-3 text-center ">
        
          <h3 className='body-heading mt-3' style={{fontSize:"23px",fontWeight:400,color:"white"}}>{heading}</h3>
          <p className="body-paragraph px-4">{paragraphs}</p>
          
      </div>
     
    </div>
</div>
  )
}

export default ServiceCard