import React from 'react'
import "./ServicesCardList.css"
import { useNavigate } from 'react-router-dom'

const serviceCardData = [
    {
        icon:"/images/icons/ser-card-1.webp",
        title:"Preventative Maintenance & Break Fix",
        content:"Manage your network environment your way. Choose a proactive, managed maintenance program or efficient break fix on demand.",
        link : "/preventative-maintenance"
    },
    {
        icon:"/images/icons/ser-card-2.webp",
        title:"Digital Signage Solutions",
        content:"Thousands of installations, all types of business channels. We understand the specific requirements of each channel, and how to help get your project completed and maintained. ",
        link : "/digital-signage-solution"
    },
    {
        icon:"/images/icons/ser-card-3.webp",
        title:"Everything Audio",
        content:"Enhance communication, inspire your team, and captivate clients with our cutting-edge digital audio solutions. Perfect for any high-traffic area, from waiting rooms to break rooms.",
        link : "/everything-audio"
    },
    {
        icon:"/images/icons/ser-card-4.webp",
        title:"Network Design",
        content:"Comprehensive services  featuring design processes, dedicated program managers, and detailed deployment plans for hardware, IT infrastructure, signage software, training, and operational planning.",
        link : "/network-design"
    },
    {
        icon:"/images/icons/ser-card-5.webp",
        title:"Structured Wiring Perfected",
        content:"Structured wiring is the highway that connects all things visual and audio. It needs to be planned thoughtfully, properly installed and you need a partner on call if anything needs changed, upgraded or serviced. ",
        link : "/structured-wiring-perfected"
    },
    {
        icon:"/images/icons/ser-card-6.webp",
        title:"Logistics Procurement, Warehousing & Provisioning",
        content:"From kits to custom orders stored in our warehouse and shipped across the country - We source mounting, cabling, audio, panels, monitor, mounts and everything else needed for custom projects.",
        link : "/logistics-procurement"
    },
    {
        icon:"/images/icons/ser-card-7.webp",
        title:"Environment Surveys and Data Insights",
        content:"Comprehensive site surveys to assess the condition and functionality of a variety of equipment. Data from site surveys are turned into  a suite of detailed reports to give clients a clear understanding of their technical infrastructure and any actions needed.",
        link : "/environment-surveys"
    },
    {
        icon:"/images/icons/ser-card-8.webp",
        title:"Remote Monitoring and Support",
        content:"Our Remote Monitoring and Support service is like having a universal remote for all your connectivity needs. We bring together all types of networks—whether it's cable or cellular—under one roof. One call, one point of contact and one bill. It's that simple.",
        link : "/remote-monitoring-support"
    },
   
]

function ServicesCardList() {
    const navigate = useNavigate()
  return (
    <div className='ServicesCardList-container standard-padding-space'>
        <div className='container'>
            <div className='row gy-4 justify-content-center'>
                {
                    serviceCardData.map((ele)=>{
                        return(
                            <div className='col-lg-4'>
                                <div className="serviceslist-card-box mx-auto">
                                    <img src={ele.icon}/>
                                    <h5 className="card-title font-bebas">{ele.title}</h5>
                                    <p className="body-paragraph">{ele.content}</p>
                                    <p onClick={()=>{navigate(ele.link)}} className="card-link font-bebas mt-3">Read More</p>
                                </div>
                            </div>
                        )
                    })
                }
                
            </div>
        </div>
    </div>
  )
}

export default ServicesCardList