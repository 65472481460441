import { useNavigate } from "react-router-dom"

function CardBox(props) {
  const navigate= useNavigate()


  const convertToSlug = (str) => {
      return str.toLowerCase().replace(/\s+/g, "-");
    };

  const redirectUserToBlog = (id) => {
      if (!id) return;
      let slug = convertToSlug(id);
  
      navigate(`/blogs/${slug}`);
    };

  return (
    <div className="card-box"  onClick={()=>{redirectUserToBlog(props.slug)}}>
        <img src={props.cardImg} alt="someImage" />
        <div className="card-text-content">
          <h6 className="sub-heading color-blue">Latest news</h6>
          <h5 className="body-heading" style={{fontSize:"22px"}}>{props.title}</h5>
          <div className="line-seprator"></div>
          <div className="d-flex align-items-center justify-content-between">
            <small className="publish-date-text">Published By: Blue Fysh</small>
            <small className="publish-date-text">{props.date}</small>

          </div>
          
        </div>
    </div>
  )
}

export default CardBox