import React, { Fragment } from 'react'
import HeroBanner from "../components/Common Banner Component/HeroBanner";

function ContactUsa() {
  return (
    <>
    <HeroBanner
    Heading={`Need help with OUR SERVICES? `}
    HighlightedSpan={"Let’s talk!"}
    whiteHiglightedText={"Blue Fysh provides digital solutions to enhance communications in a wide-ranging variety of out of-home environments by providing proactive service throughout the network lifecycle, by providing networking and digital signage solutions for key market segments."}
    Paragraph={`Contact us to find out how we can help with your project today.`}
    isChevron={false}
    isBtn={false}
    bgImg={'/images/creatives/contact-hero-usa.webp'}
    serviceForm={true}
    socialLinks={true}
    textStart={true}
    btnColor={"#1eaef4"}
    contactDetails={{
        phone:"925 832 0920",
        mail:"info@bluefysh.com",
        location:"12210 SW Main St., Portland, OR 97223",
        formRoute:"/send-contact-form-usa"
      }}
    />

</>
  )
}

export default ContactUsa