import React from 'react'
import "./TextParalalaxEffect.css"
import { useNavigate } from 'react-router-dom'

function TextParalalaxEffect({
    heading="",
    spanHeading="",
    headingBreak=true,
    content="",
    btn=true,
    btnText="",
    btnHref=""
}) {
      const navigate = useNavigate()
  return (
    <div 
        className='TextParalalaxEffect'
    >
        <div className="para-img_by_cn_section">
            <div className="container">
                <div className='text-content text-center'>
                <h3 className='body-heading' style={{fontSize:"38px"}}>{heading} {headingBreak && <br></br>}<span className='body-heading color-blue' style={{fontSize:"38px"}}>{spanHeading}</span></h3>
                    <p className='body-paragraph my-4 px-4'>{content}</p>
                    
                    <a
                   href="mailto:sales@bluefysh.com,info@bluefysh.com"
                   style={{textDecoration:"none"}}
                  >
                      <button className='blue-btn mt-3' >
                      Get Started
                      </button>
                  </a>
                </div>
                
            
            </div>
        </div>
    </div>
  )
}

export default TextParalalaxEffect