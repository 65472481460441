import React, { Fragment } from "react";
import HeroBanner from "../../components/Common Banner Component/HeroBanner";
import Testimonials from '../../components/Testimonials/Testimonials'; 
import TextContent from "../../components/TextContent/TextContent";


function RemoteMonitoringSupport() {
    return (
        <div style={{background:"#022A44"}}>  
        <HeroBanner
            preSpanHeading={""}
          Heading={"Remote Monitoring "}
          HighlightedSpan={"and Support"}
          headingBreak={true}
          Paragraph={`Seamless Connectivity Simplified`}
          bgImg={'/images/creatives/rms-hero-bg.jpg'}
          isChevron={true}
          isBtn={false}
        />
        
        <TextContent
            preSpanHeading="Effortless "
            heading = "Integration"
            shiftRow={true}
            content={"Our Remote Monitoring and Support service is like having a universal remote for all your connectivity needs. We bring together all types of networks—whether it's cable or cellular—under one roof. With just one call, you get a single, easy-to-manage point of contact and one bill for all your connections. It's that simple. No more juggling different carriers or getting tangled in multiple contracts."}
            img={"/images/creatives/rms-text-1.webp"}
            mobileTextCenter={true}
            listPointList={false}
            btn={true}
            btnText={"Let's Connect"}
            btnRef="tel"
        />
        
        <TextContent
            preSpanHeading=""
            heading = "Proactive "
            spanHeading={"Peace of Mind"}
            shiftRow={false}
            content={"Our watchful eyes never sleep. We’re on guard 24/7 to ensure your network is humming smoothly. If a hiccup occurs, we’re on it before it can become a headache for you. Our managed network is built tough, with backup systems ready to take over if needed, keeping your business connected without missing a beat."}
            img={"/images/creatives/rms-text-2.png"}
            mobileTextCenter={true}
            listPointList={false}
            btn={true}
            btnText={"Let's Connect"}
            btnRef="tel"
        />
        
        <TextContent
            preSpanHeading=""
            heading = "Always On, "
            spanHeading={"Always There"}
            shiftRow={true}
            content={"Your business can't afford downtime, and with us, it won't have to. Our geographically diverse architecture means your network is duplicated in safe locations, ready to switch over instantly if there's ever a glitch. And if the main lines go down, we’ve got a 4G backup ready to take over. Plus, our US-based support teams are here for you all day, every day. No problem is too big, no hour too late."}
            img={"/images/creatives/rms-text-3.webp"}
            mobileTextCenter={true}
            listPointList={false}
            btn={true}
            btnText={"Let's Connect"}
            btnRef="tel"
        />
        
        <TextContent
            preSpanHeading=""
            heading = "The "
            spanHeading={"Bottom Line"}
            shiftRow={false}
            content={"We keep you online so you can focus on what you do best running your business. With our Remote Monitoring and Support, you're not just getting a service; you're gaining a partner dedicated to your company's connectivity and success."}
            img={"/images/creatives/rms-text-4.webp"}
            mobileTextCenter={true}
            listPointList={false}
            btn={true}
            btnText={"Let's Connect"}
            btnRef="tel"
        />
    
         
      </div>
      )
}

export default RemoteMonitoringSupport