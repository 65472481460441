import React from 'react'
import "./ListCard.css"
import ListCardBox from "./ListCardBox"

function ListCard({
    heading="",
    preSpanHeading="",
    boxArr=[]
}) {
  return (
    <div className='list-card-container standard-padding-space'>
        <div className='container my-3'>
            <div className='text-content text-center'>
                <h3 className='body-heading'>
                    <span className='color-blue'>{preSpanHeading}</span>
                    {heading}
                </h3>

            </div>
            <div className='container-fluid mt-5'>
                <div className='row gy-4'>
                    {
                        boxArr.map((ele)=>{
                            return(
                            <div className='col-lg-6 '>
                                <ListCardBox
                                    title={ele.title}
                                    iconImg={ele.iconImg}
                                    content={ele.content}
                                />
                            </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

export default ListCard