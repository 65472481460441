import React, { Fragment } from 'react';  
import "./App.css"
import Home from './screens/Home';
import About from './screens/About';
import Services from './screens/Services';
import Contact from './screens/Contact';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import LoginScreen from "./AdminScreens/LoginScreen";
import BlogsScreen from "./AdminScreens/BlogsScreens/BlogsScreen";
import AdminScreen from "./AdminScreens/AdminScreen";
import { Routes, Route, Navigate } from 'react-router-dom';

import PreventativeMaintenance from './screens/individualServices/PreventativeMaintenance';
import DigitalSignageSolutions from './screens/individualServices/DigitalSignageSolutions';
import EverythingAudio from './screens/individualServices/EverythingAudio';
import NetworkDesign from './screens/individualServices/NetworkDesign';
import LogisticsProcurement from './screens/individualServices/LogisticsProcurement';
import EnvironmentSurveys from './screens/individualServices/EnvironmentSurveys';
import StructuredWiringPerfected from './screens/individualServices/StructuredWiringPerfected';
import RemoteMonitoringSupport from './screens/individualServices/RemoteMonitoringSupport';
import CallCenter from './screens/individualServices/CallCenter';
import OurClient from './screens/OurClient';
import Careers from './screens/Careers';
import BusinessAnalyst from './screens/individualCareers/BusinessAnalyst';
import News from './screens/news';
import NewsInner from './screens/newsInner';
import CaseStudies from './screens/CaseStudies';
import CaseStudiesInner from './screens/CaseStudiesInner';

import CaseStudyEditScreen from "./AdminScreens/CaseStudyScreens/CaseStudyEditScreen";
import CaseStudyListScreen from "./AdminScreens/CaseStudyScreens/CaseStudyListScreen";
import ContactUsa from './screens/ContactUsa';

const App = () => {

  return (
    <Fragment > 
      
      <Routes>
        <Fragment >
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Navigate to="/"></Navigate>} />
           <Route path="/about" element={<About/>} />
            <Route path="/services" element={<Services/>} />
            <Route path="/client" element={<OurClient/>} />
            <Route path="/contact" element={<Contact/>} />
            <Route path="/contact-usa" element={<ContactUsa/>} />
            <Route path="/careers" element={<Careers/>} />
            {/* <Route path="/blog" element={<News/>} /> */}
            
            {/* <Route path="/case-studies" element={<CaseStudies/>} /> */}
            <Route path="/casestudy/:id" element={<CaseStudiesInner />} />


              {/* Individual services */}
            <Route path="/preventative-maintenance" element={<PreventativeMaintenance/>} />
            <Route path="/digital-signage-solution" element={<DigitalSignageSolutions/>} />
            <Route path="/everything-audio" element={<EverythingAudio/>} />
            <Route path="/network-design" element={<NetworkDesign/>} />
            <Route path="/structured-wiring-perfected" element={<StructuredWiringPerfected/>} />
            <Route path="/logistics-procurement" element={<LogisticsProcurement/>} />
            <Route path="/environment-surveys" element={<EnvironmentSurveys/>} />
            <Route path="/remote-monitoring-support" element={<RemoteMonitoringSupport/>} />
            <Route path="/call-center-support-hub-support" element={<CallCenter/>} />

            {/* Individual careers */}
            {/* <Route path="/business-analyst" element={<BusinessAnalyst/>} /> */}

           <Route path="/blog" element={<News/>} />
          <Route path="/blogs/:id" element={<NewsInner />} />


          <Route path="/admin" element={<AdminScreen />} />
          <Route path="/admin/blogs" element={<BlogsScreen />} />
          <Route path="/login" element={<LoginScreen />} />

          <Route
            path="/admin/casestudy/:id/edit"
            element={<CaseStudyEditScreen />}
          />
          <Route path="/admin/casestudy" element={<CaseStudyListScreen />} />


        </Fragment>
      </Routes>
      <Footer /> 
    </Fragment>
  );
};
export default App; 