import React from 'react';
import ReactDOM from 'react-dom/client'; 
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import './bootstrap.min.css' 
import App from './App'; 
import ScrollToTop from "./ScrollToTop"
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import store from "./store/store";
  
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render( 
    <Provider store={store}>
        <BrowserRouter>
            <ScrollToTop/>
            <App />
        </BrowserRouter>
    </Provider>  
);

reportWebVitals();