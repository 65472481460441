import React,{useEffect} from 'react'
import HeroBanner from "../components/Common Banner Component/HeroBanner";
import { useParams } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import { listCaseStudyDetails } from "../store/actions/caseStudyActions";
import { Spinner } from 'react-bootstrap';
import CaseStContent from '../components/CaseStContent/CaseStContent';

function CaseStudiesInner() {
  const params = useParams();
  const id = params.id;
  const dispatch = useDispatch();

  const caseStudyDetails = useSelector((state) => state.caseStudyDetails);
  const { error, loading, caseStudy } = caseStudyDetails;

  useEffect(() => {
    dispatch({ type: "CASESTUDY_DETAILS_RESET" });
    dispatch(listCaseStudyDetails(id));
  }, [dispatch, id]);
  return (
    <div className='CaseStudiesInner-container'>
         
        {loading || !caseStudy ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "50px",
            paddingBottom: "50px",
          }}
        >
          <Spinner
            style={{
              color: "#1998d8",
              width: "120px",
              height: "120px",
              alignSelf: "center",
              textAlign: "center",
            }}
          />
        </div>
      ) : error ? (
        <p className="para_main" style={{ color: "red" }}>
          {error}
        </p>
      ) : (
        caseStudy &&
        !loading &&
        !error && (
          <>
            <div >
              
              <HeroBanner
                Paragraph={``}
                Heading={caseStudy.heroHeading}
                HighlightedSpan={""}
                blueHiglightedText={caseStudy.category1}
                isBtn={false}
                bgImg={'/images/creatives/inner_case_st_banner.jpg'}
                isChevron={true}
            />
              <CaseStContent
                heading={caseStudy.sec1Heading}
                para={caseStudy.sec1Para}
                para2={caseStudy.sec1Para2}
                img={caseStudy.sec1Img}
                heading2={caseStudy.sec2Heading}
                para3={caseStudy.sec2Para}
                heading3={caseStudy.sec3Heading}
                para4={caseStudy.sec3Para}
                heading4={caseStudy.sec4Heading}
                para5={caseStudy.sec4Para}
                heading5={caseStudy.sec5Heading}
                para6={caseStudy.sec5Para}
                heading6={caseStudy.sec6Heading}
                para7={caseStudy.sec6Para}
                heading7={caseStudy.sec7Heading}
                para8={caseStudy.sec7Para}
                heading8={caseStudy.sec8Heading}
                para9={caseStudy.sec8Para}
                heading9={caseStudy.sec9Heading}
                para10={caseStudy.sec9Para}
              />
            </div>
          </>
        )
      )}

        
    </div>
  )
}

export default CaseStudiesInner