import React from 'react'
import HeroBanner from "../components/Common Banner Component/HeroBanner";
import NewsList from "../components/NewsList/NewsList"
import { Helmet } from 'react-helmet'

function News() {
  return (
    <>
    
    <HeroBanner
      Paragraph={``}
      Heading={`NEWS & `}
      HighlightedSpan={"UPDATES"}
      isBtn={false}
      bgImg={'/images/creatives/blog_banner.jpg'}
      isChevron={true}
    />
   
    
    <NewsList/>
  
    </>
  )
}

export default News