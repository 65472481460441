import React from 'react'
import "./AboutParalalaxEffect.css"
import { useNavigate } from 'react-router-dom'
import AboutIcons from '../AboutIcons/AboutIcons'

function AboutParalalaxEffect({
    paddinBottom=false,
    heading="Let us be your",
    spanHeading="partner",
    content="We can take care of all of your digital & network needs. Fast, efficient, cost effective – hassle free"
}) {
      const navigate = useNavigate()
  return (
    <div 
        className={`about-paralalax-effect ${paddinBottom && "pb-1"} `}
    >
        <div className={`para-img_by_cn_section ${paddinBottom && "pb-2"}  `}>
            <div className="container">
                <div className='text-content text-center'>
                    <h3 className='body-heading'>{heading} <span className='body-heading color-blue'>{spanHeading}</span></h3>
                    <p className='body-paragraph'>{content}</p>
                </div>
                
                <AboutIcons/>
            
            </div>
        </div>
    </div>
  )
}

export default AboutParalalaxEffect