import React, { Fragment } from "react";
import ImgByContent from "../components/Image By Content/ImgByContent";
import CardBlog from '../components/CardBlog/CardBlog';
import WhyChooseUs from "../components/WhyChooseUs/WhyChooseUs";
import AboutParalalaxEffect from "../components/AboutParalalaxEffect/AboutParalalaxEffect";

import WorkIndustry from "../components/WorkIndustry/WorkIndustry";


import Hero from "../components/HomeHero_/Hero";



const Home = () => {
  return (
    <Fragment>  
      {/* <HeroBanner
        Paragraph={`Anywhere in the USA and Canada we provide industry leading solutions for your digital and network needs.`}
        Heading={`Delivering Integrated `}
        HighlightedSpan={"Technology Solutions"}
        iconURL={"/images/play-video.svg"}
        bgImg={''}
        bgVideo={true}
        isBtn={false}
        compressedHeading={true}
        offsetStyle={false}
        spanHeadingBr={true}
        partnerCompanies={true}
      /> */}
      <Hero/>
      <ImgByContent
        Heading={"Transform Your"}
        HighlightedHeading={"Digital Network Environment"}
        subHeading={"About Blue Fysh"}
        Paragraph={`We are a network integration & digital installation company that builds, maintains, and manages digital signage systems. We harness & utilize data to improve both content and productivity.`}
        btnText={"About Us"}
        href={"/about"}
        imgLink="/images/creatives/home-text-0.1.webp"
      />
      <WhyChooseUs/>

      <ImgByContent
        Heading={"Environment Reports and "}
        HighlightedHeading={"Data Insights"}
        subHeading={""}
        Paragraph={`Learn how our team of highly trained technicians conducts comprehensive site surveys to assess the condition and functionality of your equipment and network across your organization.`}
        btnText={"Let's Connect"}
        href={"tel"}
        imgLink="/images/creatives/home-text-2.webp"
        shiftRow={true}
      />

      <AboutParalalaxEffect
        paddinBottom={true}
      />
      <WorkIndustry/>
      <CardBlog />
    </Fragment>
  );
};

export default Home;
