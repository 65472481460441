import React, { Fragment } from 'react'
import HeroBanner from "../components/Common Banner Component/HeroBanner";

function Contact() {
  return (
    <Fragment>
        <HeroBanner
        Heading={`Need help with OUR SERVICES? `}
        HighlightedSpan={"Let’s talk!"}
        whiteHiglightedText={"Blue Fysh provides digital solutions to enhance communications in a wide-ranging variety of out of-home environments by providing proactive service throughout the network lifecycle, by providing networking and digital signage solutions for key market segments."}
        Paragraph={`Contact us to find out how we can help with your project today.`}
        isChevron={false}
        isBtn={false}
        bgImg={'/images/creatives/contact-hero.webp'}
        serviceForm={true}
        socialLinks={true}
        textStart={true}
        btnColor={"#1eaef4"}
        contactDetails={{
          phone:"905 713 0564",
          mail:"sales@bluefysh.com",
          location:"2495 Lloydtown Road, King City ON L7B 1A3",
          formRoute:"/send-contact-form-main"
        }}
        />

    </Fragment>
  )
}

export default Contact