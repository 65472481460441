import React from 'react'
import "./WorkIndustry.css"
import IndustryCard from './IndustryCard'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import {Autoplay} from "swiper/modules"

const industryData = [
  { img: "/images/icons/industry-icon-1.png", title: "Retail" },
  { img: "/images/icons/industry-icon-2.png", title: "Entertainment and Sports Venues " },
  { img: "/images/icons/industry-icon-3.png", title: "Restaurants" },
  { img: "/images/icons/industry-icon-4.png", title: "Hospitality" },
  { img: "/images/icons/industry-icon-5.png", title: "Convenience Stores" },
   
  { img: "/images/icons/industry-icon-6.png", title: "Financial Services" },
  { img: "/images/icons/industry-icon-7.png", title: "Automotive & EV Stations" },
  { img: "/images/icons/industry-icon-8.png", title: "Medical and Healthcare Facilities" },
  { img: "/images/icons/industry-icon-9.png", title: "Corporate Communications, Employee Experience" },
  { img: "/images/icons/industry-icon-10.png", title: "Digital out of Home (DOOH) Advertising Networks" },

];


function WorkIndustry() {

    const handlePrevButtonClickInd = () => {
        const swiperInstance = document.querySelector('#ind-cards-slider').swiper;
        swiperInstance.slidePrev();
      };
      
      const handleNextButtonClickInd = () => {
        const swiperInstance = document.querySelector('#ind-cards-slider').swiper;
        swiperInstance.slideNext();
      };
  return (
    <div className='work-industry-container text-center standard-padding-space'>
        <div className='container my-3'>
        <h3 className='body-heading mx-auto'>Digital Solutions For <br></br><span className='body-heading color-blue'>Every Market</span></h3>
        <p className='body-paragraph'>Blue Fysh provides digital solutions to enhance communications in a wide-ranging variety of out of-home environments and key market segments including:</p>
        <div className='industry-list container mt-5'>
            {/* <div className='row'>
                {industryData.map((ele)=>{
                    return <IndustryCard img={ele.img} title={ele.title} />
                })}
            </div> */}
            <Swiper
            spaceBetween={30}
            autoplay={{
              delay: 1500,
              disableOnInteraction: false,
            }}
            loop={true}
            modules={[Autoplay]}
            navigation={{
              nextEl: '.swiper-button-next-ind',
              prevEl: '.swiper-button-prev-ind',
            }}
            breakpoints={{
                200:{
                  slidesPerView: 1,
                },
                550: {
                  slidesPerView: 2,
                },
                800: {
                  slidesPerView: 3,
                },
                1200: {
                  slidesPerView: 4,
                },
              }}
            pagination={{ clickable: true }}
            observer ={true}
            observeParents={true}
            parallax={true}
            className="ind-list pt-4"
            style={{position:"relative"}}
            id="ind-cards-slider"
            >
                  <div className="swiper-button-prev-ind" onClick={handlePrevButtonClickInd}>
                        <img src="/images/icons/indus-carousel-arrow-left.svg" className="arrow-img"/>
                    </div>
              {
                industryData.map((ele,ind)=>{
                  return(<>
                    <SwiperSlide key={ind}>
                      <IndustryCard
                        img={ele.img}
                        title={ele.title}
                      />
                    </SwiperSlide>
                     </>)
                })
              }
                    <div className="swiper-button-next-ind" onClick={handleNextButtonClickInd}>
                        <img src="/images/icons/indus-carousel-arrow-right.svg" className="arrow-img"/>
                    </div>
        </Swiper>
        
        </div>


        </div>
    </div>
  )
}

export default WorkIndustry