import React from 'react'
import  { useState, useEffect } from 'react';



const NumberCounter = ({ end, repeatScroll = false, speed }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      if (count < end) {
        setCount(prevCount => prevCount + 1);
      } else if (repeatScroll) {
        setCount(0); 
      }
    }, speed); 

    return () => clearInterval(timer);
  }, [count, end, repeatScroll]);

  return <h4 className='font-bebas my-2'>{count}+</h4>;
};

function AboutIconContainer(props) {
  return (
    <div className='col-lg-3 col-md-6'>

    <div className='icon-container'>
        <img src={props.img} className='icon-container-img'/>
        {/* <h4 className='font-bebas my-2'>{props.rating}</h4> */}
        {
          props.noScroll
          ?
            <h4 className='font-bebas my-2'>{props.rating}</h4>
          :
            <NumberCounter speed={props.sliderSpeed} end={props.ratingNumber} repeatScroll={props.repeatScroll}/>

        }
        <p className='body-paragraph'>{props.title}</p>
    </div>
    </div>
  )
}

export default AboutIconContainer