import React from 'react'
import HeroBanner from "../components/Common Banner Component/HeroBanner";
import ImgByContent from '../components/Image By Content/ImgByContent';
import CarrersForm from '../components/CarrersForm/CarrersForm';

function Careers() {
  return (
    <div style={{background:"#022A44"}}>
        <HeroBanner
            Paragraph={``}
            Heading={`JOB `}
            HighlightedSpan={"OPPORTUNITIES"}
            isBtn={false}
            bgImg={'/images/creatives/career_banner.jpg'}
            isChevron={true}
        />

      <ImgByContent
        Heading={"Connect with "}
        HighlightedHeading={"us"}
        subHeading={""}
        Paragraph={`BLUE FYSH is all about working with passionate, highly motivated people who want to be part of a successful team. If you are a creative problem solver and have experience in the digital technology space. Interested in furthering your career? Contact us to learn about joining our team!`}
        btnText={"Let's Connect"}
        href='tel'
        shiftRow={true}
        imgLink="/images/creatives/home-text-1.png"
      />

      {/* <div className='container my-4 pt-4 text-center '>
        <h3 className='body-heading'>JOB <span className='color-blue'>OPPORTUNITIES</span></h3>
      </div>

        <CareersCardList/> */}

        
        <CarrersForm/>

        <div style={{height:'50px'}}></div>
    </div>
  )
}

export default Careers