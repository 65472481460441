import React, { Fragment } from 'react'
import ImgByContent from "../components/Image By Content/ImgByContent";
import HeroBanner from "../components/Common Banner Component/HeroBanner";
import AboutParalalaxEffect from '../components/AboutParalalaxEffect/AboutParalalaxEffect';
import TextCard from '../components/TextCard/TextCard';

const About = () => {
  return (
    <Fragment>
      <HeroBanner
        Paragraph={`Revitalize Your Digital Ecosystem with Expert Care!`}
        Heading={``}
        HighlightedSpan={"ABOUT US"}
        iconURL={"/images/play-video.svg"}
        isBtn={false}
        bgImg={'/images/creatives/about_banner.webp'}
        isChevron={true}
      />
      <TextCard/>

      <ImgByContent
        Heading={" technology made easy"}
        HighlightedHeadingBefore={"Navigating"}
        subHeading={""}
        Paragraph={`At Blue Fysh, we understand that the relentless pace of digital innovation can feel overwhelming. That's why our dedicated support teams are here to lift the weight off your internal IT squad's shoulders, providing a seamless, cost-effective solution that propels your business forward. `}
        
        shiftRow={true}
        imgLink="/images/creatives/about-text-1.webp"
      />
      <ImgByContent
        Heading={" Support"}
        HighlightedHeadingBefore={"24/7/365"}
        subHeading={""}
        Paragraph={`Embrace the future with confidence.  Our flexible, scalable services are a perfect fit for clients needs. With years of expertise under our belts, our seasoned technicians are ready to manage your install, move, add, and change (IMAC) requests across the nation. We're here for you—anytime, any day—with our 24/7/365 support, ensuring your infrastructure and end-user environments are in expert hands.\nBlue Fysh LLC goes beyond the surface, offering a one-stop solution care approach for your IT equipment and systems. We're anchored in the best practices of the ITIL framework, ensuring meticulous management, tracking, resolution, and reporting of all your service requests.`}
        
        shiftRow={false}
        imgLink="/images/creatives/about-text-2.webp"
      />
      <ImgByContent
        Heading={" Trained Technicians"}
        HighlightedHeadingBefore={"HIghly"}
        subHeading={""}
        Paragraph={`Our founders– have decades of experience and have worked on thousands of installations with both multi-national and regional clients across a large variety of business sectors.\nIt’s not just about keeping the gears turning smoothly; it's about safeguarding your voyage. Our support crew is rigorously trained and certified to handle products from tech giants like HP, IBM, Lenovo, EMC, Cisco, Ricoh, and Samsung, among others. They've navigated the strictest security checks, both internally and as required by our clients, to ensure your peace of mind.\nChart your course towards digital excellence. Contact Blue Fysh LLC today and discover how we can navigate your technology needs!`}
        
        shiftRow={true}
        imgLink="/images/creatives/about-text-3.webp"
      />
      
      <AboutParalalaxEffect
        bgImage='/images/creatives/about-section-3rd-bg.webp'
        Heading={"Discover the Power of Print with "}
        HighlightedHeading={"Tech Hood"}
        subHeading={"ABOUT TECH HOOD PRINTS"}
        Paragraph={`Tech Hood harnesses the latest printing technologies to ensure that your materials are not only visually appealing but also of the highest quality. We stay at the forefront of advancements to bring you the best in the industry. Your vision is our guide, and we take pride in delivering personalized solutions that exceed your expectations.`}
        btnText={"Get a Quote"}
        href={"tel"}
      />

    </Fragment>
  )
}

export default About;