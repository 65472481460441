import React from 'react'
import "./CaseStContent.css"

function CaseStContent({
    heading,
    para,
    para2,
    img,
    heading2,
    para3,
    heading3,
    para4,
    heading4,
    para5,
    heading5,
    para6,
    heading6,
    para7,
    heading7,
    para8,
    heading8,
    para9,
    heading9,
    para10,
    paddingBottom,
    paddingTop,
  }) {
    return (
        <div
          className='standard-padding-space'
        >
          <div className='container'>
            <div className="row gy-5">
              <div className='col-12' >
                <div>
                  <img className="img-fluid" src={img} />
                </div>
              </div>
              <div className='col-12'>
                <div>
                  <h1 className="body-heading pb-4">{heading}</h1>
                  <p className="body-paragraph">{para}</p>
                  <br />
                  <p className="body-paragraph pb-5">{para2}</p>
    
                  {heading2 && (
                    <>
                      <h1 className="body-heading pb-4">{heading2}</h1>
                      <p className="body-paragraph">{para3}</p>
                    </>
                  )}
                  {heading3 && (
                    <>
                      <h1 className="body-heading pb-4">{heading3}</h1>
                      <p className="body-paragraph">{para4}</p>
                    </>
                  )}
                  {heading4 && (
                    <>
                      <h1 className="body-heading pb-4">{heading4}</h1>
                      <p className="body-paragraph">{para5}</p>
                    </>
                  )}
                  {heading5 && (
                    <>
                      <h1 className="body-heading pb-4">{heading5}</h1>
                      <p className="body-paragraph">{para6}</p>
                    </>
                  )}
                  {heading6 && (
                    <>
                      <h1 className="body-heading pb-4">{heading6}</h1>
                      <p className="body-paragraph">{para7}</p>
                    </>
                  )}
                  {heading7 && (
                    <>
                      <h1 className="body-heading pb-4">{heading7}</h1>
                      <p className="body-paragraph">{para8}</p>
                    </>
                  )}
                  {heading8 && (
                    <>
                      <h1 className="body-heading pb-4">{heading8}</h1>
                      <p className="body-paragraph">{para9}</p>
                    </>
                  )}
                  {heading9 && (
                    <>
                      <h1 className="body-heading pb-4">{heading9}</h1>
                      <p className="body-paragraph">{para10}</p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
}

export default CaseStContent