import React, { Fragment } from "react";
import HeroBanner from "../../components/Common Banner Component/HeroBanner";
import Testimonials from '../../components/Testimonials/Testimonials'; 
import TextContent from "../../components/TextContent/TextContent";
import TextParalalaxEffect from "../../components/TextParalalaxEffect/TextParalalaxEffect";
import AboutParalalaxEffect from "../../components/AboutParalalaxEffect/AboutParalalaxEffect";
import GridCard from "../../components/GridCard/GridCard";


function LogisticsProcurement() {
    return (
        <div style={{background:"#022A44"}}>  
        <HeroBanner
          Paragraph={`Get what you need when you need it.`}
          preSpanHeading={"Logistics Procurement,"}
          Heading={`Warehousing & Provisioning`}
          headingBreak={true}
          bgImg={'/images/creatives/lp-hero-bg.jpg'}
          isChevron={true}
          isBtn={false}
        />
        
            
        <TextContent
            heading = "Kitting, Delivery, "
            spanHeading="Logistics and More"
            shiftRow={true}
            content={"From kits to custom orders stored in our warehouse and shipped across the country - We regularly source on behalf of our clients mounting, cabling, audio, panels, monitor, mounts and everything else needed for custom projects. We can procure from the smallest project to projects that require significant capital investment.\nProject failures often stem from delivery issues like incorrect configurations, oversized components, or various other factors. Blue Fysh addresses this with its own kitting, warehousing, and procurement capabilities."}
            img={"/images/creatives/lp-text-1.png"}
            mobileTextCenter={true}
            listPointList={false}
            btn={true}
            btnText={"Let's Connect"}
            btnRef="tel"
        />

        <GridCard
            spanHeading="Comprehensive"
            heading=" Services"
            whiteContent=""
            content="Our Logistics, Procurement, Warehousing, and Provisioning services are tailored to ensure that every component you require is at your fingertips precisely when you need it. We cater  to a diverse range of needs, from individual kits to large-scale custom orders, meticulously stored and managed within our warehousing facilities. Our Logistics staff  also have the added capability of configuring equipment, managing scripts and more."
        />

       <AboutParalalaxEffect
        heading="Let Us Be Your "
        spanHeading="Logistics Partner"
        content="Goods procured, kitted, and organized on time to meet your needs."
       />
         
      </div>
      )
}

export default LogisticsProcurement