import React,{useState} from 'react'
import "./TextCard.css"

function TextCardBox({
    title,
    iconImg,
    breifPara,
    actualPara,
    listPoint
}) {
    const [dropOpen , setDropOpen] = useState(false)
  return (
    <div
      className="text-card-box p-4 px-5 d-flex flex-column align-items-start"
      onClick={() => {
        setDropOpen((prev) => !prev);
      }}
    >
      <div className="content-info d-flex gap-3 align-items-center justify-content-start my-4">
        <img src={iconImg} className="" />
        <h5 className="body-heading mb-0" style={{fontWeight:400 , fontSize:"23px"}}>{title}</h5>
      </div>
      <p
        className={`body-paragraph ${dropOpen ? "d-none" : "d-block close"}`}
      >
        {breifPara}
        {listPoint && <ul><li>{listPoint[1]}</li></ul>}
      </p>
      <p
        className={`body-paragraph ${dropOpen ? "d-block open" : "d-none "}`}
      >
        {actualPara}
        {listPoint && <ul>{listPoint.map((ele)=>{return(<li className='my-2'>{ele}</li>)})}</ul>}
      </p>
      
      <img
        src={
          dropOpen
            // ? "/images/icons/text-card-arrow-open.svg"
            ? "/images/icons/text-card-arrow-close.svg"
            : "/images/icons/text-card-arrow-close.svg"
        }
        className="align-self-end my-3"
        // style={{ cursor: "pointer" }}
        style={dropOpen
          ? {transition:".3s",transform: "rotate(190deg)"}
          : {transition:".3s",transform: "rotate(0deg)"}
        }
      />
    </div>
  );
}

export default TextCardBox