import "./Testimonials.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from "swiper/modules";

import TestimonialCard from "../Genral Components/TestimonialCard";

function Testimonials() {
  const blogData = [
    {
      clientName: "AV, Team Leader",
      clientImg: "/images/icons/test-card-user-male.png",
      clientReview:
        "Good Afternoon, I wanted to say thank you to you and your colleague for the prompt and professional service. I appreciate you taking the time to answer my questions regarding the new unit and for the swift installation and clean up",
    },
    {
      clientName: "MM, Team Leader",
      clientImg: "/images/icons/test-card-user-female.png",
      clientReview:
        "Thank you again for your amazingly kind, respectful service, and helping store the old device.  You left the place clean and tidy and were mindful of our clients that were in the waiting area and or walking in that area.   I appreciate the professionalism you showed us today.",
    },
    {
      clientName: "MK, Service Manager",
      clientImg: "/images/icons/test-card-user-male.png",
      clientReview:
        "Just wanted to send you a note and thank you for the TV installation. Installation was smooth, efficient and had no impact on our operations.",
    },
    {
      clientName: "KH, Manager",
      clientImg: "/images/icons/test-card-user-male.png",
      clientReview:
        "Your company and your technicians have been invaluable partner to us for over 3 years. We work closely with them every day on the deployment of digital menu boards for a number quick service restaurant (QSR) brand across Canada.",
    },
    {
      clientName: " KH, Manager",
      clientImg: "/images/icons/test-card-user-female.png",
      clientReview:
        "You have been there every step of the way through over 550 McDonald’s and Tim Hortons drive throughs. With their help we have reach all corners of the ten provinces and two of the three territories in Canada.",
    },
    {
      clientName: "Manager",
      clientImg: "/images/icons/test-card-user-female.png",
      clientReview:
        "Their high level of organization and detailed work has made our lives very easy when managing these large demanding corporations.",
    },
    {
      clientName: "Project Manager",
      clientImg: "/images/icons/test-card-user-male.png",
      clientReview:
        "I know when I call upon Blue Fysh  to match our rigorous schedule they often exceed our expectations in the way of quality and professionalism, both in the office and in field.  We attribute a lot of our success our partners like you.",
    },
    {
      clientName: "PG, Digital Project Manager",
      clientImg: "/images/icons/test-card-user-female.png",
      clientReview:
        "You have demonstrated a very good example of cooperation between our two teams. We have the same goal!",
    },
    {
      clientName: "PM Stingray",
      clientImg: "/images/icons/test-card-user-male.png",
      clientReview:
        "I wanted to thank you again for rectifying the situation at [our client] so efficiently! :) We are all very happy with the techs and the result). Please share this with the techs you hired… he was very professional.",
    },
    {
      clientName: "Branch Manager  BMO",
      clientImg: "/images/icons/test-card-user-female.png",
      clientReview:
        "Please be advised the installation is complete for both the audio and digital screen for this location.I must tell you that both teams who came were professional and fantastic to work with! - Thank you,",
    },
  ];

  const handlePrevButtonClick = () => {
    const swiperInstance = document.querySelector('#articles-cards-slider').swiper;
    swiperInstance.slidePrev();
  };
  
  const handleNextButtonClick = () => {
    const swiperInstance = document.querySelector('#articles-cards-slider').swiper;
    swiperInstance.slideNext();
  };


  return (
    <div className="Testimonials-container">
      <div className="container">
    <div className="row d-flex align-items-center gy-4">

      <div className="col-lg-6 col-md-12 mb-0 text-center text-lg-start">
          <div className="text-content mx-auto mx-lg-0">
              {/* <p className="sub-heading">Testimonials</p> */}
              <h3 className="body-heading">Our Track Record  <span className="body-heading color-blue">Speaks Volumes</span></h3>
              <p className="body-paragraph">With a vast portfolio of installations across diverse business channels, Blue Fysh stands as a beacon of innovation in digital display solutions. Take a look at comments from our  satisfied clients and see how we can bring your digital aspirations to fruition.</p>
              
             
              <a href="mailto:sales@bluefysh.com,info@bluefysh.com"><button className="blue-btn mt-2 mb-0">Get Started</button></a>
          </div>
          {/* <a href="https://www.google.com/maps/place/Tech+Hood/@43.6951168,-79.6980144,17z/data=!4m8!3m7!1s0x882b1553db933c9f:0xd6337e60af08712f!8m2!3d43.6951168!4d-79.6980144!9m1!1b1!16s%2Fg%2F11rc7jt7b1?hl=en-CA&entry=ttu" target="blank"><button className="blue-btn my-md-0 mb-4">Learn More</button></a> */}
      </div>
      <div className="col-lg-6 col-md-12 d-flex align-items-center gap-4">
      {/* <button className="swiper-button-prev arrow-btn d-md-flex d-none" onClick={handlePrevButtonClick}><img src='/images/icons/drop-down-icon-w.png' style={{transform:"rotate(90deg)"}}/></button> */}
        <Swiper
            spaceBetween={50}
            slidesPerView={1} 
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            loop={true}
            modules={[Autoplay]}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
            pagination={{ clickable: true }}
            observer={true}
            observeParents={true}
            parallax={true}
            className="testimonials-list  "
            id="articles-cards-slider"
            >
              {
                blogData.map((clientData,ind)=>{
                  return(<>
                    <SwiperSlide key={ind}>
                      
                      <TestimonialCard clientImg={clientData.clientImg} clientName={clientData.clientName} clientReview={clientData.clientReview} />
                      
                    </SwiperSlide>
                     </>)
                })
              }
              
        </Swiper>
        {/* <button className="swiper-button-next arrow-btn d-md-flex d-none" onClick={handleNextButtonClick}><img src='/images/icons/drop-down-icon-w.png' style={{transform:"rotate(270deg)"}}/></button> */}
      </div>
      </div>
  </div>
</div>
  )
}

export default Testimonials