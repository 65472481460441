import React, { Fragment } from "react";
import HeroBanner from "../../components/Common Banner Component/HeroBanner";
import Testimonials from '../../components/Testimonials/Testimonials'; 
import TextContent from "../../components/TextContent/TextContent";

const list3text = [
    "Expert troubleshooting from our problem-solving wizards",
    "Scalable support that adapts to your schedule",
    "Comprehensive help desk services",
    "Diligent remote monitoring",
    "Prompt incident resolution and management.",
    "Detailed virtual site assessments",
    "Service Level Agreement (SLA)",
    "Diverse multichannel support",
    "Unwavering commitment to quality assurance",
]

const list4text = [
    "Enhanced customer experiences",
    "Elevated Customer Satisfaction (CSAT) scores",
    "Decreased Total Cost of Ownership (TCO)",
    "Increased operational uptime.",
    "Minimized service interruptions.",
    "Efficient incident escalation and comprehensive reporting",
    "Streamlined project management with dedicated points of contact.",
]

const list5text = [
    "Consistent, high-quality customer experiences",
    "Technological proficiency",
    "Personalized and effective communication",
    "A team of problem solvers with a wealth of product and service knowledge",
    "A culture of resilience and reliability",
    "An ethos of continuous improvement and meticulous attention to detail",
]

function CallCenter() {
    return (
        <div style={{background:"#022A44"}}>  
        <HeroBanner
            Heading={"Call Center, Support "}
          HighlightedSpan={"Hub Support"}
          spanHeadingBr={true}
          Paragraph={`Welcome to Blue Fysh Support Hub`}
          bgImg={'/images/creatives/ccs-hero-bg.jpg'}
          isChevron={true}
          isBtn={false}
        />
        
        <TextContent
            preSpanHeading=""
            heading = "Excellence in "
            spanHeading={"Virtual Support"}
            shiftRow={true}
            content={"Dive into a new era of customer care with Blue Fysh Support Hub, where we tackle the complexities of modern support head-on. We recognize the hurdles of delivering consistent, personalized customer experiences amidst communication challenges and high turnover rates. Our mission is to cut through these obstacles with precision and depth, ensuring every customer interaction is a step towards loyalty and satisfaction. Join us on a journey to redefine virtual support and elevate your customer experience to new heights."}
            img={"/images/creatives/ccs-text-1.png"}
            mobileTextCenter={true}
            listPointList={false}
            btn={true}
            btnText={"Let's Connect"}
            btnRef="tel"
        />
        <div className="container standard-padding-space">
            <div className="text-center mx-auto adjustable-small-width">
                <h3 className="body-heading">Redefining <span className="color-blue">Virtual Support with Depth and Precision.</span></h3>
                <p className="body-paragraph">Your Journey to Superior Customer Experience Begins Here</p>
            </div>

        </div>

        <TextContent
            preSpanHeading=""
            heading = "The Current Landscape of "
            spanHeading={"Support Challenges"}
            shiftRow={false}
            content={"Navigating through today's customer support complexities can be daunting. Businesses often grapple with:"}
            img={"/images/creatives/ccs-text-2.png"}
            mobileTextCenter={true}
            adjecentbulletlistPointList={true}
            adjecentbulletlistPoint={[
                "Inconsistent",
                "Customer experiences",
                "Communication barriers",
                "Knowledge limitations",
                "Lack of personalization",
                "Customer dissatisfaction",
                "Inconsistent quality assurance",
               "High turnover rates",
            ]}
            postBtn={false}
        />

        <TextContent
            preSpanHeading="Our Blueprint for "
            heading = "Success"
            shiftRow={true}
            content={"At Blue Fysh, we are committed to transforming the customer service paradigm. Our formula is simple:"}
            img={"/images/creatives/ccs-text-3.png"}
            mobileTextCenter={true}
            adjecentbulletlistPointList={true}
            adjecentbulletlistPoint={[
                "Active Engagement",
                "Effective Assistance",
                "Rapid Response",
            ]}
            afterListContent="We are dedicated to not just meeting, but surpassing your expectations with a proactive approach to customer support that emphasizes continuous improvement and bespoke solutions. Our goal is to craft frictionless, delightful customer journeys that forge enduring bonds of trust and distinction."
            postBtn={false}
            btnText={"Let's Connect"}
            btnRef="tel"
        />

        <TextContent
            heading = "Introducing Our "
            spanHeading={"Support Hub"}
            shiftRow={false}
            content={"Blue Fysh's Support Hub is a nexus of customer-centric solutions designed to meet and exceed the dynamic needs of your business:"}
            img={"/images/creatives/ccs-text-4.png"}
            mobileTextCenter={true}
            bulltePointList={true}
            bulletPoint={list3text}
            postBtn={false}
            btnText={"Let's Connect"}
            btnRef="tel"
        />

        <TextContent
            heading = "Support Hub "
            spanHeading={"Advantages"}
            shiftRow={true}
            content={"At Blue Fysh, we believe in empowering your business with:"}
            img={"/images/creatives/ccs-text-5.png"}
            mobileTextCenter={true}
            bulltePointList={true}
            bulletPoint={list4text}
            postBtn={false}
            btnText={"Let's Connect"}
            btnRef="tel"
        />
        <TextContent
            heading = "Seal of "
            spanHeading={"Commitment"}
            shiftRow={false}
            content={"Our pledge to you is unwavering:"}
            img={"/images/creatives/ccs-text-6.png"}
            mobileTextCenter={true}
            bulltePointList={true}
            bulletPoint={list5text}
            afterlistBlueText="Discover a superior path with Blue Fysh's Integrated Technology Services."
        />
    
      </div>
      )
}

export default CallCenter